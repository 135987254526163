import { Box, Container } from "@mui/material";

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  serverTimestamp,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FaRegTrashAlt } from "react-icons/fa";
import { UserAuth } from "../../context/AuthContext";
import { db } from "../../firebase";
import "./Account.scss";
const Account = () => {
  const [settings, setSettings] = useState([]);
  const [key, setKey] = useState("");
  const [title, setTitle] = useState("");
  const { user } = UserAuth();

  // Create todo
  const createTodo = async (e) => {
    e.preventDefault(e);
    if (key === "") {
      alert("Please enter a valid Settings");
      return;
    }
    await addDoc(collection(db, "settings"), {
      title: title,
      key: key,
      timestamp: serverTimestamp(),
    });
    setKey("");
    setTitle("");
  };

  // Read todo from firebase
  useEffect(() => {
    const q = query(collection(db, "settings"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let todosArr = [];
      querySnapshot.forEach((doc) => {
        todosArr.push({ ...doc.data(), id: doc.id });
      });
      setSettings(todosArr);
    });
    return () => unsubscribe();
  }, []);

  // Delete todo
  const deleteTodo = async (id) => {
    await deleteDoc(doc(db, "settings", id));
  };

  function formatDate(date) {
    const formatDate = new Date(
      date.seconds * 1000 + date.nanoseconds / 1000000
    );
    return formatDate.toLocaleTimeString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  return (
    <>
      <Helmet>
        <title>XsiSec.com | Add Settings</title>
        <meta name="Settings" content="Settings" />
      </Helmet>
      <Container maxWidth="lg" style={{ width: "100%" }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            position: "relative",
            zIndex: 1,
            textAlign: "center",
            paddingTop: "120px",
          }}
        >
          <p />

          <h4>User</h4>
          <li>userId:{user.uid}</li>
          <li>Display-name:{user.displayName}</li>

          <form onSubmit={createTodo}>
            <input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className={title}
              type="text"
              placeholder="Add title for setting"
            />
            <p />

            <input
              value={key}
              onChange={(e) => setKey(e.target.value)}
              className={key}
              type="text"
              placeholder="Add key for Tiny cloud Editor"
            />

            <p />
            <button>Add</button>
          </form>
          <ul>
            {settings.map((todo, index) => (
              <>
                <li className="">
                  <br />
                  <p>
                    title:
                    <br />
                    {todo.title}
                  </p>
                  <p>
                    key:
                    <br />
                    {todo.key}
                  </p>
                  <p>
                    Created at:
                    <br />
                    {formatDate(todo.timestamp)}
                  </p>
                  <button onClick={() => deleteTodo(todo.id)}>
                    {<FaRegTrashAlt />}
                  </button>
                </li>
              </>
            ))}
          </ul>
          {settings.length < 1 ? null : (
            <p>{`Settings ${settings.length} settings made =)`}</p>
          )}
        </Box>
      </Container>
    </>
  );
};

export default Account;
