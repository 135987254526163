import "./ChangeHistory.scss";

import { Box, Container, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { collection, onSnapshot, query } from "firebase/firestore";

import { db } from "../../../firebase";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  headingName: {
    color: theme.palette.color.white,
  },
  aboutCard: {
    color: theme.palette.color.white,
  },
}));

function ChangeHistory() {
  const theme = useTheme();
  const [changeHistory, setChangeHistory] = useState([]);


  function formatDate(date) {
    const formatDate = new Date(
      console.log(date),
      date.seconds * 1000 + date.nanoseconds / 1000000
    );
    return formatDate.toLocaleTimeString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  // Read todo from firebase
  useEffect(() => {
    const q = query(collection(db, "changehistory"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let todosArr = [];
      querySnapshot.forEach((doc) => {
        todosArr.push({ ...doc.data(), id: doc.id });
      });
      setChangeHistory(todosArr);
    });
    return () => unsubscribe();
  }, []);

  const classes = useStyles();

  return (
    <>
      <Container maxWidth="lg">
        {/* <Particle /> */}
        <Box className="home-section">
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <h1
                className={`${classes.headingName} heading-name`}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <strong className="main-name"> Change-History</strong>
              </h1>

              <br />

              <div
                style={
                  // color: "#ff9100" ,
                  {
                    fontSize: "1rem",
                    lineHeight: "1.4",
                    // margin: "0 0 0 -40px",
                    fontWeight: "bold",
                    fontFamily:
                      'Monaco, "Bitstream Vera Sans Mono", "Lucida Console", Terminal, monospace',
                    color: "#000000",
                    WebkitFontSmoothing: "antialiased",
                  }
                }
              >
                <div
                  className="List"
                  style={
                    // color: "#ff9100" ,
                    {
                      color: theme.palette.color.ListItemFontColour,
                    }
                  }
                >
                  <ul>
                    {changeHistory.map((todo, index) => (
                      <>
                        <li className="">
                          {todo?.description} {moment(todo?.timestamp.toDate().toISOString()).format(" YYYY-MM-DD-HH")} ✔️
                        </li>
                      </>
                    ))}
                  </ul>
                  {/* <li >Implemented Tags for Security Types (appear on every blogcard) - 2023-03-17  </li>
                  <li>Fixed Firebase-rules Critical-bug found by my colleague - 2023-03-14 ✔️ </li>
                  <li>Fixed Fixed comments for each components earlier not possible to post - 2023-03-20 ✔️ </li>
                  <li>Added Github Repos in skills section - 2023-03-20 ✔️ </li> */}
                </div>
              </div>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
}
export default ChangeHistory;
