import React from "react";

function Filter({ todosPerPage, setTodosPerPage }) {
  return (
    <form className="filter-menu">
      <label>
        Display Items per page:{" "}
        <select
          className="test"
          value={todosPerPage}
          onChange={(e) => setTodosPerPage(e.target.value)}
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
        </select>
      </label>
    </form>
  );
}

export default Filter;
