/* eslint-disable react/jsx-pascal-case */
import { Box, Container, Typography } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import { toast } from "react-toastify";
import { UserAuth } from "../../../../context/AuthContext";
import { db, storage } from "../../../../firebase";
import "./AddGamingPost.scss";

const initialState = {
  title: "",
  author: "",
  tags: [],
  trending: "no",
  category: "",
  description: "",
  content: [],
  timestamp: "",
  os: "",
};
var tinyCloudKey = "";

const categories = [
  "HackTheBox",
  "TryHackMe",
  "Tutorials",
  "Security",
  "Other",
];




const AddGamingPost = () => {
  const { user } = UserAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  if (location.pathname.includes("update")) {
    //console.log("Location is Update", location.pathname);
  } else {
    // console.log("Location is Add", location.pathname);
  }
  const editorRef = useRef(null);

  const [form, setForm] = useState(initialState);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(null);
  const [editorData, setEditorData] = useState("");
  const [showPortSwiggerLevel, setShowPortSwiggerLevel] = useState(false);
  const [showPortSwiggerLab, setShowPortSwiggerLab] = useState(false);
  const [settings, setSettings] = useState([]);

  const {
    title,
    author,
    tags,
    category,
    trending,
    description,
    content,
    timestamp,
    os,
  } = form;
  useEffect(() => {
    const uploadFile = () => {
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setProgress(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
            toast.info("Image uploaded successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setForm((prev) => ({ ...prev, imgUrl: downloadUrl }));
          });
        }
      );
    };

    file && uploadFile();
  }, [file]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleTags = (tags) => {
    setForm({ ...form, tags });
  };

  const handleTrending = (e) => {
    setForm({ ...form, trending: e.target.value });
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log("Adding Blog");
    if (category && tags && title && description && trending) {
      if (!id) {
        try {
          await addDoc(collection(db, "gaming"), {
            ...form,
            timestamp: serverTimestamp(),
            userId: user.userId,
            content: editorData,
            likedBy: [],
            likedByIp: [],
            clappedBy: [],
            clappedByIp: [],
          });
          toast.success("gaming-post has been created", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        } catch (err) {
          // console.log(err);
        }
      } else {
        try {
          await updateDoc(doc(db, "gaming", id), {
            ...form,
            timestamp: serverTimestamp(),
            userId: user.userId,
            content: editorData,
          });
          toast.success("gaming-post has been updated", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        } catch (err) {
          // console.log(err);
        }
      }
    } else {
      return toast.error("All fields need to be used", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }

    navigate("/projectlist");
  };

  //date
  var str = "";
  if (timestamp > 0) {
    str = new Date(timestamp.seconds * 1000);
  }
  const date_formated_timestamp = moment(str).format(
    "dddd, MMMM Do YYYY, h:mm:ss a"
  );

  // const log = () => {
  //   if (editorRef.current) {
  //     setEditorData(editorRef.current.getContent());
  //     //console.log(editorRef.current.getContent());
  //   }
  // };



  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  settings.forEach((x) => {
    tinyCloudKey = x.key;
  });

  return (
    <>
      <Helmet>
        <title>XsiSec.com | GamingList Add Gaming-post</title>
        <meta
          name="description"
          content="Contact us if you have any question or requests"
        />
      </Helmet>
      <Container maxWidth="lg" style={{ width: "100%" }}>
        {/* <Particle /> */}
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            position: "relative",
            zIndex: 1,
            textAlign: "center",
            paddingTop: "120px",
          }}
        >
          <Typography
            style={{ fontSize: "22px", color: "#ff9100", fontWeight: "bold" }}
          >
            {id ? "Update blog" : "Create blogpost"}
          </Typography>

          <div>
            {id ? (
              <input
                style={{ marginBottom: 10 }}
                disabled
                type="text"
                className="form-control input-text-box"
                name="timestamp"
                value={date_formated_timestamp}
                onChange={handleChange}
              />
            ) : null}
          </div>

          <form
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
            onSubmit={handleSubmit}
          >
            <input
              style={{ width: "50%", marginBottom: 10 }}
              type="text"
              className="form-control input-text-box"
              placeholder="author"
              name="author"
              value={author}
              onChange={handleChange}
            />

            <input
              style={{ width: "50%" }}
              type="text"
              className="form-control input-text-box"
              placeholder="title"
              name="title"
              value={title}
              onChange={handleChange}
            />

            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                height: 500,
                width: "100%",
                columnGap: 10,
                marginTop: "20px",
              }}
            >
              <Box style={{ flex: 1 }}>
                <Editor
                  apiKey={tinyCloudKey}
                  onInit={(evt, editor) => {
                    setEditorData(editor.getContent({ format: "html" }));
                    editorRef.current = editor;
                  }}
                  value={editorData}
                  onEditorChange={(newValue, editor) => {
                    setEditorData(newValue);
                    // setText(editor.getContent({format: 'text'}));
                  }}
                  initialValue=""
                  init={{
                    height: 500,
                    menubar: "file edit view insert format tools table",
                    plugins:
                      "powerpaste casechange searchreplace autolink directionality advcode visualblocks visualchars image link media mediaembed codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists checklist wordcount tinymcespellchecker help formatpainter permanentpen charmap linkchecker emoticons advtable export print autosave preview",
                    toolbar:
                      "undo redo print spellcheckdialog formatpainter | blocks fontfamily fontsize | bold italic underline forecolor backcolor | link image addcomment showcomments  | alignleft aligncenter alignright alignjustify lineheight | checklist bullist numlist indent outdent | removeformat preview",

                    content_style:
                      "body, td, pre {color:#000; font-family:Verdana, Arial, Helvetica, sans-serif; font-size:14px; margin:8px;}",
                    codesample_languages: [
                      { text: "HTML/XML", value: "markup" },
                      { text: "JavaScript", value: "javascript" },
                      { text: "CSS", value: "css" },
                      { text: "PHP", value: "php" },
                      { text: "Ruby", value: "ruby" },
                      { text: "Python", value: "python" },
                      { text: "Java", value: "java" },
                      { text: "C", value: "c" },
                      { text: "C#", value: "csharp" },
                      { text: "C++", value: "cpp" },
                    ],
                  }}
                />
              </Box>
            </Box>

            <TagsInput
              value={tags}
              onChange={handleTags}
              name="tags"
              placeHolder="Enter tags"
            />

            <div className="col-12 py-3">
              <p className="trending">is the post trending?</p>
              <div className="form-check-inline mx-2">
                <input
                  type="radio"
                  className="form-check-input"
                  value="yes"
                  name="radioOption"
                  checked={trending === "yes"}
                  onChange={handleTrending}
                />
                <label htmlFor="radioOption" className="form-check-label">
                  Yes&nbsp;
                </label>
                <input
                  type="radio"
                  className="form-check-input"
                  value="no"
                  name="radioOption"
                  checked={trending === "no"}
                  onChange={handleTrending}
                />
                <label htmlFor="radioOption" className="form-check-label">
                  No
                </label>
              </div>
            </div>
     
       

            <div className="col-12 py-3">
              <textarea
                className="form-control description-box"
                placeholder="description"
                value={description}
                name="description"
                onChange={handleChange}
              />
            </div>
            {id && <img src={form.imgUrl} alt={form.title} />}
            <div className="mb-3">
              <input
                type="file"
                className="form-control"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </div>
            <div style={{ marginBottom: 150 }}>
              <button
                style={{
                  backgroundColor: "#1a2332",
                  color: "#fff",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "20px",
                }}
                type="submit"
                disabled={progress !== null && progress < 100}
              >
                {id ? "Update Project post" : "Publish"}
              </button>
            </div>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default AddGamingPost;
