import "./Footer.scss";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  IconButton,
  Typography,
  useTheme
} from "@mui/material";
import {
  FiGithub,
  FiInstagram,
  FiTwitch,
  FiTwitter,
  FiYoutube
} from "react-icons/fi";
import React, { useState } from "react";

import { AiOutlineReddit } from "react-icons/ai";
import { BsStackOverflow } from "react-icons/bs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";

// import "./social.css";



function Footer() {
  const theme = useTheme();

  const { width, height } = useWindowDimensions();
  
  const [hideFooter, setHideFooter] = useState(false);
  let date = new Date();
  let year = date.getFullYear();
  function scrollHandler() {
    if (window.scrollY >= 20) {
      setHideFooter(true);
    } else {
      setHideFooter(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);
  return (
    <Box
      sx={{
        height: "fit-content",
        width: "100%",
        backgroundColor: theme.palette.color.FooterBackground,
        position: "relative",
        zIndex: "100",
        display: "flex",
        flexDirection: "column",
        boxShadow:
          "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          padding: "20px 0px",
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: width >= 800 ? "row" : "column",
          // justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: width >= 800 ? "row" : "column",
            // justifyContent: "space-between",
            // alignItems: "center",
          }}
        >
          {width >= 800 ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
                // justifyContent: "space-between",
                // alignItems: "center",
              }}
            >
              <Typography sx={styles.footerTitle}>Site Map</Typography>
              <Button
                component={Link}
                to="/"
                sx={styles.buttons}
                style={{ color: theme.palette.color.lightBlueForFooter }}
              >
                Home
              </Button>
              <Button
                component={Link}
                to="/bloglist"
                sx={styles.buttons}
                style={{ color: theme.palette.color.lightBlueForFooter }}
              >
                Blog Posts
              </Button>
              <Button
                component={Link}
                to="/skills"
                sx={styles.buttons}
                style={{ color: theme.palette.color.lightBlueForFooter }}
              >
                Skills
              </Button>
              <Button
                component={Link}
                to="/changehistory"
                sx={styles.buttons}
                style={{ color: theme.palette.color.lightBlueForFooter }}
              >
                Release Notes
              </Button>
            </Box>
          ) : (
            <Accordion
              sx={{
                marginBottom: "5px",
                backgroundColor: theme.palette.color.whiteColorForFooter,
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    style={{ color: theme.palette.color.white }}
                  />
                }
              >
                <Typography sx={styles.footerTitleAcc}>Site Map</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center",
                    // justifyContent: "space-between",
                    // alignItems: "center",
                  }}
                >
                  <Button
                    component={Link}
                    to="/"
                    sx={styles.buttonsAcc}
                    style={{ color: theme.palette.color.lightBlueForFooter }}
                  >
                    Home
                  </Button>
                  <Button
                    component={Link}
                    to="/"
                    sx={styles.buttonsAcc}
                    style={{ color: theme.palette.color.lightBlueForFooter }}
                  >
                    Skills
                  </Button>
                  <Button
                    component={Link}
                    to="/"
                    sx={styles.buttonsAcc}
                    style={{ color: theme.palette.color.lightBlueForFooter }}
                  >
                    Release notes
                  </Button>
                  <Button
                    component={Link}
                    to="/changehistory"
                    sx={styles.buttonsAcc}
                    style={{ color: theme.palette.color.lightBlueForFooter }}
                  >
                    Blog Posts
                  </Button>
                 
                </Box>
              </AccordionDetails>
            </Accordion>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",

              // justifyContent: "space-between",
            }}
          >
            {width >= 800 ? (
              <Typography sx={styles.footerTitle}>Socials</Typography>
            ) : null}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <IconButton
                sx={{
                  padding: "4px",
                  "&:hover": {
                    transition: "linear all 250ms",
                    transform: "scale(1.1)",
                  },
                }}
                onClick={() =>
                  window.open("https://github.com/xsisec", "_blank").focus()
                }
              >
                <FiGithub
                  className=" social_icon_style"
                  color="#fff"
                  fill="#fff"
                  style={{
                    backgroundColor: "#000",
                    padding: 4,
                    borderRadius: "20px",
                  }}
                />
              </IconButton>
              <IconButton
                sx={{
                  padding: "4px",
                  "&:hover": {
                    transition: "linear all 250ms",
                    transform: "scale(1.1)",
                  },
                }}
                onClick={() =>
                  window
                    .open("https://www.instagram.com/xsisecofficial/", "_blank")
                    .focus()
                }
              >
                <FiInstagram
                  className=" social_icon_style"
                  color="#fff"
                  fill="#125688"
                  style={{
                    backgroundColor: "#fff",
                    padding: 4,
                    borderRadius: "20px",
                  }}
                />
              </IconButton>
              <IconButton
                sx={{
                  padding: "4px",
                  "&:hover": {
                    transition: "linear all 250ms",
                    transform: "scale(1.1)",
                  },
                }}
                onClick={() =>
                  window
                    .open("https://www.twitch.tv/xsisecofficial", "_blank")
                    .focus()
                }
              >
                <FiTwitch
                  className=" social_icon_style"
                  color="#fff"
                  fill="#6441A4"
                  style={{
                    backgroundColor: "#6441A4",
                    padding: 4,
                    borderRadius: "20px",
                  }}
                />
              </IconButton>
              <IconButton
                sx={{
                  padding: "4px",
                  "&:hover": {
                    transition: "linear all 250ms",
                    transform: "scale(1.1)",
                  },
                }}
                onClick={() =>
                  window
                    .open("https://www.reddit.com/user/xsisec", "_blank")
                    .focus()
                }
              >
                <AiOutlineReddit
                  className=" social_icon_style"
                  color="#fff"
                  fill="#fff"
                  style={{
                    backgroundColor: "#FF4500",
                    padding: 4,
                    borderRadius: "20px",
                  }}
                />
              </IconButton>
              <IconButton
                sx={{
                  padding: "4px",
                  "&:hover": {
                    transition: "linear all 250ms",
                    transform: "scale(1.1)",
                  },
                }}
                onClick={() =>
                  window
                    .open(
                      "https://stackoverflow.com/users/4537270/xsisecofficial",
                      "_blank"
                    )
                    .focus()
                }
              >
                <BsStackOverflow
                  className=" social_icon_style"
                  color="#fff"
                  fill="#fff"
                  style={{
                    backgroundColor: "#F47F24",
                    padding: 4,
                    borderRadius: "20px",
                  }}
                />
              </IconButton>
              <IconButton
                sx={{
                  padding: "4px",
                  "&:hover": {
                    transition: "linear all 250ms",
                    transform: "scale(1.1)",
                  },
                }}
                onClick={() =>
                  window
                    .open("https://twitter.com/XsiSecOfficial", "_blank")
                    .focus()
                }
              >
                <FiTwitter
                  className=" social_icon_style"
                  color="#fff"
                  fill="#1DA1F2"
                  style={{
                    backgroundColor: "#fff",
                    padding: 4,
                    borderRadius: "20px",
                  }}
                />
              </IconButton>
              <IconButton
                sx={{
                  padding: "4px",
                  "&:hover": {
                    transition: "linear all 250ms",
                    transform: "scale(1.1)",
                  },
                }}
                onClick={() =>
                  window
                    .open(
                      "https://www.youtube.com/channel/UCdUJ92IRutGcp-7r5Etcg0w",
                      "_blank"
                    )
                    .focus()
                }
              >
                <FiYoutube
                  className=" social_icon_style"
                  color="#fff"
                  fill="#FF0000"
                  style={{
                    backgroundColor: "#fff",
                    padding: 4,
                    borderRadius: "20px",
                  }}
                />
              </IconButton>
            </div>
          </Box>

          {width >= 800 ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "center",

                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={styles.footerTitle}>Privacy</Typography>
              <Button
                component={Link}
                to="/privacy-policy"
                sx={styles.buttons}
                style={{ color: theme.palette.color.lightBlueForFooter }}
              >
                Privacy Policy
              </Button>
            </Box>
          ) : (
            <Accordion
              sx={{
                marginBottom: "5px",
                backgroundColor: theme.palette.color.whiteColorForFooter,
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    style={{ color: theme.palette.color.lightBlueForFooter }}
                  />
                }
              >
                <Typography sx={styles.footerTitleAcc}>Privacy</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    // justifyContent: "space-between",
                    // alignItems: "center",
                  }}
                >
                  <Button
                    component={Link}
                    to="/privacy-policy"
                    sx={styles.buttonsAcc}
                    style={{ color: theme.palette.color.lightBlueForFooter }}
                  >
                    Privacy Policy
                  </Button>
                  <Button
                    component={Link}
                    to="/"
                    sx={styles.buttonsAcc}
                    style={{ color: theme.palette.color.lightBlueForFooter }}
                  >
                    FAQ
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
      </Container>
      <Typography sx={styles.poweredBy}>
        Powered by XsiSec.com 2024 © 
      </Typography>
    </Box>
  );
}

export default Footer;

const styles = {
  footerTitle: {
    color: "#00bf9a",
    fontFamily: "Nunito",
    fontSize: "15px",
    textAlign: "center",
    borderBottom: "1px solid #fff",
    width: "100%",
    maxWidth: "100px",
  },
  footerTitleAcc: {
    color: "#00bf9a",
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Nunito",
    width: "100%",
    maxWidth: "100px",
  },
  credits: {
    color: "#9fef00",
    fontWeight: "bold",
  },
  poweredBy: {
    color: "#00bf9a",
    fontWeight: "bold",
    fontFamily: "Nunito",
    fontSize: "15px",
    textAlign: "center",
    marginBottom: "20px",
  },
  buttons: {
    color: "#FFFFFF",
    fontFamily: "Nunito",

    textDecoration: "none",
    padding: 0,
    "&:hover": {
      color: "#9fef00",
    },
  },
  buttonsAcc: {
    color: "#fff",
    padding: 0,
    // backgroundColor: " rgb(0, 191, 155,0.2)",
    textDecoration: "none",
    margin: "0px 10px",
    "&:hover": {
      color: "#9fef00",
    },
  },
};
