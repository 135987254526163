import { createContext, useContext, useEffect, useState } from "react";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth, db } from "../firebase";
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const CKEKey = "07olljh6oajmsjr3x2lxh7ydxb0htzbs3d3vlwt9uuvmcq9i";
  const [user, setUser] = useState(null);
  const [loadingHome, setLoadingHome] = useState(true);
  const [activePage, setActivePage] = useState("");
  const [defaultTheme, setDefaultTheme] = useState(true);

  const signIn = async (email, password) => {
    return await signInWithEmailAndPassword(auth, email, password);
  };
  const signUp = async (email, password) => {
    return await createUserWithEmailAndPassword(auth, email, password)
      .then(async (user) => {
        console.log("User signed Up in Auth", user);
        try {
          return await setDoc(doc(db, "users", user.user.uid), {
            timestamp: serverTimestamp(),
            userId: user.user.uid,
            role: "user",
            likedPosts: [],
          })
            .then((res) => {
              toast.success("User has been created", {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              return true;
            })
            .catch((err) => {
              toast.error("Error during creation", {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              return false;
            });
        } catch (err) {
          console.log(err);
          toast.error("Error during creation", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          return false;
        }
      })
      .catch((err) => {
        console.log("Error while sign up", err);
        return false;
      });
  };
  const logout = () => {
    return signOut(auth);
  };
  const handleChangeTheme = () => {
    console.log("Handle Theme change", defaultTheme);

    setDefaultTheme(!defaultTheme);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      //console.log(currentUser);
      if (currentUser) {
        const docRef = doc(db, "users", currentUser.uid);
        const docSnap = await getDoc(docRef);
        setUser(docSnap.data());
      } else {
        setUser(null);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        CKEKey,
        logout,
        signIn,
        signUp,
        loadingHome,
        setLoadingHome,
        activePage,
        setActivePage,
        defaultTheme,
        handleChangeTheme,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
