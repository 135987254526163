import {
  Grid,
  Typography
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./PortfolioMostPopular.scss";
const PortfolioMostPopular = ({ blogs }) => {
  const navigate = useNavigate();

  return (
    <div>
  
      <Grid container spacing={2} justifyContent="center"></Grid>
    </div>
  );
};

export default PortfolioMostPopular;
const styles = {
  cardStyle: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    minHeight: "320px",
    minWidth: "300px",
    transitionDuration: "0.3s",
    borderRadius: "4px",
    textDecoration: "none",
    // marginBottom: "10px",
  },
};
