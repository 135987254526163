import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where
} from "firebase/firestore";

import { Box, Container, Grid, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import Helmet from 'react-helmet';
import { toast } from "react-toastify";
import { db } from "../../../../firebase";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { images } from "../../../utility/image_array projects";
import Spinner from "../../../utility/Spinner/Spinner";
import Catagories from "../../Categories/Catagories";
import Tags from "../../Tags/Tags";
import ProjMostPopular from "../ProjMostPopular/ProjMostPopular";
import Trending from "../ProjTrending/Trending";
import ProjectSection from "./../ProjectSection/ProjectSection";

import "./ProjectList.scss";
const ProjectList = () => {
  const { width, height } = useWindowDimensions();
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState(projects);
  const [tags, setTags] = useState([]);
  const [catagories, setCatagories] = useState([]);
  const [trendBlogs, setTrendBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(6);

  const getTrendingBlogs = async () => {
    const blogRef = collection(db, "projects");
    const trendQuery = query(blogRef, where("trending", "==", "yes"));
    const querySnapshot = await getDocs(trendQuery);
    let trendBlogs = [];
    querySnapshot.forEach((doc) => {
      trendBlogs.push({
        id: doc.id,
        image_bg: getRandomImage(),
        ...doc.data(),
      });
    });
    setTrendBlogs(trendBlogs);
  };

  useEffect(() => {
    const blogsRef = collection(db, "projects");
    const blogsQuery = query(blogsRef, orderBy("timestamp", "desc"));
    const unsub = onSnapshot(
      blogsQuery,
      (snapshot) => {
        //console.log("Blog snapshot is", snapshot.docs.length);
        let list = [];
        let tags = [];
        let catagories = [];
        snapshot.docs.forEach((doc) => {
          //console.log("Blog is", doc.data());
          //console.log("Catagory is", doc.get("category"));
          catagories.push(doc.get("category"));
          tags.push(...doc.get("tags"));
          list.push({
            id: doc.id,
            image_bg: getRandomImage(),
            ...doc.data(),
          });
        });
        const sortedUniqueTags = sortByOccurence(tags);
        const sortedUniqueCatagories = sortByOccurence(catagories);
        // const uniquetags = [...new Set(tags)];
        setCatagories(sortedUniqueCatagories);
        setTags(sortedUniqueTags);
        setProjects(list);
        setFilteredProjects(list);
        setLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    getTrendingBlogs();
  }, []);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentBlogs = filteredProjects.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const sortByOccurence = (arr) => {
    //find the counts using reduce
    var cnts = arr.reduce(function (obj, val) {
      obj[val] = (obj[val] || 0) + 1;
      return obj;
    }, {});
    //Use the keys of the object to get all the values of the array
    //and sort those keys by their counts
    var sorted = Object.keys(cnts).sort(function (a, b) {
      return cnts[b] - cnts[a];
    });
    return sorted;
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete ?")) {
      try {
        setLoading(true);
        await deleteDoc(doc(db, "projects", id));
        toast.success("Project-post successfully deleted", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const getRandomImage = () => {
    var num = Math.floor(Math.random() * images.length);

    return images[num];
  };
  const filterBySearch = (event) => {
    // Access input value
    const query = event.target.value;
    // Create copy of item list
    var updatedList = [...projects];
    // Include all elements which includes the search query
    // updatedList = updatedList.filter((item) => item.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    // )
    updatedList = updatedList.filter(
      (eachBlog) =>
        eachBlog.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
    // Trigger render with updated values
    setFilteredProjects(updatedList);
  };
  const filterByTag = (tag) => {
    //console.log("Git this tag to searhc", tag);
    // Access input value
    // Create copy of item list
    var allBlogs = [...projects];
    // Include all elements which includes the search query
    // updatedList = updatedList.filter((item) => item.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    // )
    var updatedList = [];
    if (tag !== "reset") {
      allBlogs.map((eachBlog) => {
        if (eachBlog.tags.includes(tag)) {
          console.log("Matched Tag");
          updatedList.push(eachBlog);
        } else {
          console.log("Not matched tag");
        }
      });
    } else {
      updatedList = allBlogs;
    }

    setFilteredProjects(updatedList);
  };
  const filterByCatagory = (category) => {
    //console.log("Git this tag to searhc", category);
 
    var allBlogs = [...projects];

    var updatedList = [];
    if (category !== "reset") {
      allBlogs.map((eachBlog) => {
        if (eachBlog.category === category) {
          console.log("Matched Category");
          updatedList.push(eachBlog);
        } else {
          console.log("Not matched tag");
        }
      });
    } else {
      updatedList = allBlogs;
    }

    setFilteredProjects(updatedList);
  };

  let timestamp = [];

  projects.map((eachBlog, index) => {
    timestamp.push(eachBlog.timestamp);
  });

  const theme = useTheme();

  return (
    <>
  

      <Container maxWidth="xl">
      <Helmet>
      <title>XsiSec.com | ProjectList</title>
      <meta name='description' content='Contact us if you have any question or requests' />
    </Helmet>
        
        {/* <Particle /> */}

        {loading ? (
          <div style={{ position: "absolute", top: "50%", right: "50%" }}>
            <Spinner />
          </div>
        ) : (
          <Box
            style={{
              position: "relative",
              zIndex: 1,
              width: "100%",
              height: "100%",
              paddingTop: "20px",
              paddingBottom: "80px",
            }}
          >
            {trendBlogs.length > 0 ? <Trending blogs={trendBlogs} /> : null}
            <ProjMostPopular blogs={filteredProjects} />

            <Grid
              container
            // sx={{
            //   display: "flex",
            //   flexDirection: "row",
            //   height: "fit-content",
            // }}
            >
       
              <div
                style={{ display: "flex", width: "100%", justifyContent: "center" }}
              >
                <input
                  placeholder="Search for Projects..."
                  style={{
                    backgroundColor: theme.palette.color.searchForAnyPostPageBackground,
                    width: "100%",
                    maxWidth: "500px",
                    height: "40px",
                    fontFamily: "Geo",
                    borderRadius: "4px",
                    border: "3px solid transparent",
                    color:theme.palette.color.searchForAnyPostPageFontColor,
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                  id="search-box"
                  onChange={filterBySearch}
                />
              </div>


              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <br />
              </div>
              
              <Catagories
                catagories={catagories}
                filterByCatagory={filterByCatagory}
              />


              <Grid
                item
                xl={9}
                lg={9}
                md={9}
                sm={12}
                xs={12}
                sx={{ paddingRight: "10px" }}
              >
                <ProjectSection
                  blogs={currentBlogs}
                  handleDelete={handleDelete}
                  postsPerPage={postsPerPage}
                  setPostsPerPage={setPostsPerPage}
                  totalPosts={filteredProjects?.length}
                  paginate={paginate}
                  currentPage={currentPage}
                  timestamp={timestamp}
                />
              </Grid>
              <Grid
                item
                xl={3}
                lg={3}
                md={3}
                sm={12}
                xs={12}
                sx={{ paddingLeft: "10px" }}
              >
                <Tags
                  direction={width <= 900 ? "row" : null}
                  tags={tags}
                  filterByTag={filterByTag}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Container>
    </>
  );
};

export default ProjectList;
