import React from "react";
import { useLocation } from "react-router-dom";
import "./Catagories.scss";
import { useTheme } from "@mui/material";


const Catagories = ({ catagories, filterByCatagory, direction }) => {
  // var randomColor = Math.floor(Math.random()*16777215).toString(16);
  // console.log("#"+randomColor)
  const location = useLocation();

  const first10Catagories = catagories?.slice(0, 9);
  const restOfTheCatagories = catagories?.slice(10, 19);
  function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const theme = useTheme();

  return (
    <div
      style={{
        width: "100%",
        marginLeft: "5%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >


      <div style={{ display: "flex", flexDirection: "row" }}>
        {catagories?.length <= 10 ? (
          <div className="tags__row">
            {catagories?.map((catagory, index) => (
              <button
                className="tag"
                style={{ color: theme.palette.color.yellow, background: theme.palette.color.lightBlueForTags }}
                key={index}
                value={catagory}
                onClick={(e) => filterByCatagory(e.target.value)}
              >
                {catagory}
              </button>
            ))}
            <button
              className="tag"
              style={{
                backgroundColor: theme.palette.color.lightRed,
                color: "#1a2332",
                fontWeight: "bold",
              }}
              value={"reset"}
              onClick={(e) => filterByCatagory(e.target.value)}
            >
              Reset Catagory
            </button>
          </div>
        ) : (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              className={direction === "row" ? "tags__row" : "tags"}
              style={{ flexGrow: 1 }}
            >
              <button
                className="tag"
                style={{
                  backgroundColor: theme.palette.color.lightRed,
                  color: "#1a2332",
                  fontWeight: "bold",
                }}
                value={"reset"}
                onClick={(e) => filterByCatagory(e.target.value)}
              >
                Reset Catagory
              </button>
              {first10Catagories?.map((catagory, index) => (
                <button
                  className="tag"
                  key={index}
                  value={catagory}
                  onClick={(e) => filterByCatagory(e.target.value)}
                >
                  {catagory}
                </button>
              ))}
            </div>
            <div
              className={direction === "row" ? "tags__row" : "tags"}
              style={{ flexGrow: 1 }}
            >
              {restOfTheCatagories?.map((catagory, index) => (
                <button
                  className="tag"
                  key={index}
                  value={catagory}
                  onClick={(e) => filterByCatagory(e.target.value)}
                >
                  {catagory}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Catagories;
