import React, { useState, useEffect } from "react";
import { FaAngleUp } from "react-icons/fa";
import {  useTheme } from "@mui/material";

import "./index.css";

const ScrollToTop = () => {

  const theme = useTheme();
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="top-to-btm">
      {" "}
      {showTopBtn && (
        <FaAngleUp className="icon-position icon-style"
        style={{ 
          background: theme.palette.color.ScrollToTop,
          border: "2px solid #fff",
          borderRadius: "50%",
          height: "50px",
          width: "50px",
          color: "#fff",
          cursor: "pointer",
          animation: "movebtn 3s ease-in-out infinite",
          transition: "all .5s ease-in-out"
        }}
         onClick={goToTop} />
      )}{" "}
    </div>
  );
};
export default ScrollToTop;
