import "./BlogPostDetail.scss";

import { Box, CircularProgress, Container, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { useLocation, useParams } from "react-router-dom";

import Comments from "../Comments/Comment";
import Helmet from "react-helmet";
import Tags from "../../Tags/Tags";
import { UserAuth } from "../../../../context/AuthContext";
import { db } from "../../../../firebase";
import moment from "moment";
import postBg from "../../../../Assets/post-detail-bg.jpg";
import {  MdPreview } from 'md-editor-rt';
import 'md-editor-rt/lib/style.css';


const BlogPostDetail = ({ code, language }) => {
  const theme = useTheme();

  const { state } = useLocation();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  console.log("State is", state);

  const [blog, setBlog] = useState(null);
  const { user } = UserAuth();

  const getBlogDetail = async () => {
    const docRef = doc(db, "blogs", id);
    const blogDetail = await getDoc(docRef);
    console.log("Blog data is", blogDetail.data());

    let blog = blogDetail.data();
    blog = { ...blog, id: id };
    setBlog(blog);
    // setBlog(blogDetail.data());
    setLoading(false);
  };

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "blogs", id), async (blogDetail) => {
      console.log("Blog data is", blogDetail.data());
      if (user) {
        setLoading(false);
      } else {
      }
      let blog = blogDetail.data();
      blog = { ...blog, id: id };
      setBlog(blog);
      // setBlog(blogDetail.data());
      setLoading(false);
    });
    return () => {
      unsub();
    };
  }, [id]);
  // useEffect(() => {
  //   getBlogDetail();
  // }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Container maxWidth="xl" sx={{ paddingTop: "120px" }}>
      {/* <Particle /> */}
      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Container
          maxWidth="xl"
          sx={{
            width: "100%",
            // marginTop: "80px",
            position: "relative",
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // backgroundColor: "rgb(9,46,32,0.3)",
            backgroundColor: theme.palette.color.deepLightGreyForBlogDetails,
            borderRadius: 3,
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "400px",
              maxHeight: "400px",
              // backgroundColor: "yellow",
              marginBottom: "50px",
              marginTop: "50px",
              transition: "all 250ms ease-in-out",
              borderRadius: "20px",
              "&:hover": {
                cursor: "pointer",
                transform: "scale(1.05)",
              },
            }}
          >
            <img
              src={postBg}
              alt="Blogpost detail"
              style={{
                width: "100%",
                height: "400px",

                // objectFit: "cover",
              }}
            />
            <Box
              sx={{
                width: "100%",
                // backgroundColor: "yellow",
                marginTop: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: "30%",
                textAlign: "center",
                width: "100%",
              }}
            >
              <h1
                style={
                  // color: "#ff9100" ,
                  {
                    fontSize: "2.5rem",
                    lineHeight: "1.5",
                    // margin: "0 0 0 -40px",
                    fontWeight: "bold",
                    fontFamily:
                      'Monaco, "Bitstream Vera Sans Mono", "Lucida Console", Terminal, monospace',
                    color: "#9fef00",
                    textShadow:
                      "0 1px 1px rgb(0 0 0 / 10%), 0 0 5px rgb(181 232 83 / 10%), 0 0 10px rgb(181 232 83 / 10%)",
                    letterSpacing: "-1px",
                    WebkitFontSmoothing: "antialiased",
                  }
                }
              >
                {blog?.title}
                <Helmet>
                  <title>XsiSec.com | {blog?.title}</title>
                  <meta
                    name="description"
                    content="Contact us if you have any question or requests"
                  />
                </Helmet>
              </h1>
              <p
                style={{
                  color: "#eaeaea",
                  fontSize: "16px",
                  lineHeight: "1.5",
                  fontFamily:
                    'Monaco, "Bitstream Vera Sans Mono", "Lucida Console", Terminal, monospace',
                }}
              >
                By {" " + blog?.author}
              </p>
            </Box>
          </Box>

          <div
            style={{ width: "100%", height: "1px", backgroundColor: "gold" }}
          />
          <div
            style={{
              width: "100%",
              // backgroundColor: "yellow",
              // background: "rgba(255, 255, 255, 0.2)",
              borderRadius: "10px",
              marginTop: "20px",
              overflowX: "auto",
              // overflowY: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                padding: "10px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "flex-start",
                }}
              >
                <p
                  style={{
                    color: "#63c0f5",
                    textShadow: "0 0 5px rgb(104 182 255 )",
                  }}
                >
                  {moment(blog?.timestamp.toDate().toISOString()).format(
                    " YYYY-MM-DD-HH:mm:ss a"
                  )}
                </p>
                <h1
                  style={{
                    fontWeight: "normal",
                    fontFamily:
                      'Monaco, "Bitstream Vera Sans Mono", "Lucida Console", Terminal, monospace',
                    color: "#b5e853",
                    letterSpacing: "-0.03em",
                    textShadow:
                      "0 1px 1px rgb(0 0 0 10%), 0 0 5px rgb(181 232 83 10%), 0 0 10px rgb(181 232 83 )",
                    fontSize: "28px",
                  }}
                >
                  {blog?.description}
                </h1>

                <p
                  style={{
                    color: theme.palette.color.lightBlueForBlogDetails,
                    fontSize: "16px",
                    lineHeight: "1.5",
                    fontFamily:
                      'Monaco, "Bitstream Vera Sans Mono", "Lucida Console", Terminal, monospace',
                  }}
                >{`By ${blog?.author}`}</p>
              </Box>
            </Box>


            

            <div
              className="blog__content"
              style={{ color: theme.palette.color.lightBlueForBlogDetails }}
            >
        
              <MdPreview
              modelValue={blog?.content}
              language="en-US"
              showCodeRowNumber="true"
              previewTheme={theme.palette.color.EditorForDisplayPreviewTheme}
              noMermaid="true"
              />
            </div>
          </div>

          <div
            style={{ width: "100%", height: "1px", backgroundColor: "gold" }}
          />
          {blog?.category}
          <Tags direction="row" tags={blog?.tags} />

          <Comments />
        </Container>
      )}
    </Container>
  );
};

export default BlogPostDetail;
