import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link } from "react-router-dom";

function GitHubData({ todos }) {
  const theme = useTheme();
  const onClick=()=>{
    console.log("working");
  }

  return (
    <ul className="github">
      {todos.map((todo) => (
        <li key={todo.id} className="list-group-item">
          <Typography
              target="_blank"
              component="a"
              href={todo.html_url}
            sx={{
              fontWeight: "bold",
              color: theme.palette.color.GithubTitleColor,
              textDecoration: "none",
              fontSize: "14px",
              "&:hover": {
                color: "#ff8800",
                cursor: "pointer",
              },
            }}
          >
            {todo?.name}
          </Typography>

          <Typography
            variant="body2"
            style={{
              color: theme.palette.color.GithubDescriptionColor,
              fontSize: "12px",
            }}
          >
            {todo?.description}
          </Typography>

          <Typography
            variant="body2"
            style={{
              color: theme.palette.color.GithubDateColor,
              //textShadow: `${theme.palette.color.limeGreenForBlogCards} 0px 0px 5px`,
              fontSize: "12px",
            }}
          >
            {todo?.created_at}
          </Typography>

          <hr />
        </li>
      ))}
    </ul>
  );
}

export default GitHubData;
