import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Grid, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { FaCommentAlt, FaHandsWash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Background from "../../../../Assets/code_bg.jpg";
import { UserAuth } from "../../../../context/AuthContext";
import PaginationComponent from "../../Pagination/PaginationComponent";
import trending_no from "../../../../Assets/trending_no.png";
import trending_yes from "../../../../Assets/trending_yes.png";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";

import "./PortfolioSection.scss";

import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PortfolioSection = ({
  blogs,
  handleDelete,
  postsPerPage,
  setPostsPerPage,
  totalPosts,
  paginate,
  currentPage,
}) => {
  const { user } = UserAuth();
  const navigate = useNavigate();

  console.log("Blogs recieved in Blog section", blogs);
  const notifyCopied = (e) => {
    // e.stopPropagation();
    toast("Blog-link copied to Clipboard!", { position: "bottom-left" });
  };

  const current_url = window.location.href;

  const theme = useTheme();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ToastContainer />
      {/* <Typography
        sx={{
          margin: "1em 0 0.5em 0",
          fontWeight: "600",
          fontFamily: "'Titillium Web', sans-serif",
          position: "relative",
          fontSize: "30px",
          lineHeight: "30px",
          padding: "15px 15px ",
          color: theme.palette.color.white,
          borderRadius: "0 10px 0 10px",
          width: "fit-content",
          backgroundColor: theme.palette.color.orange,
          "&:hover": {
            cursor: "pointer",
            color: "#1a2332",
            background: "#e6e6e6",
          },
        }}
      >
        All Posts
      </Typography> */}
      <Grid spacing={2} container justifyContent="center">
        {blogs.map((item, index) => {
          var catagoryColor = {
            background: "",
            text: "",
          };
          if (item.category === "Still") {
            catagoryColor.background = "#981ca3";
            catagoryColor.text = "#fff";
          }
          if (item.category === "3D") {
            catagoryColor.background = "#981ca3";
            catagoryColor.text = "#fff";
          }
          if (item.category === "Animation") {
            catagoryColor.background = "#1c7ba3";
            catagoryColor.text = "#fff";
          }
          if (item.category === "Desktop") {
            catagoryColor.background = "#1ca33b";
            catagoryColor.text = "#fff";
          }
          return (
            <Grid item xl={4} lg={6} md={6} sm={6} xs={12} key={item?.id}>
              <Card sx={styles.cardStyle} key={item?.id}>
                <CardMedia
                  onClick={() => {
                    navigate(`/portfolio/${item.id}`, {
                      state: { id: item.id },
                    });
                  }}
                  component="img"
                  height="140"
                  image={item.image_bg ? item.image_bg : Background}
                  alt="Background"
                  sx={styles.imgStyle}
                />
                <CardContent
                  component={Link}
                  to={`/portfolio/${item.id}`}
                  state={{ id: item.id }}
                  style={{
                    backgroundColor:
                      theme.palette.color.PortfolioCardBackground,
                    // height: "200px",
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    textDecoration: "none",
                  }}
                >
                  <Box>
                    <Typography
                      gutterBottom
                      component={Link}
                      to={`/portfolio/${item.id}`}
                      state={{ id: item.id }}
                      sx={{
                        fontWeight: "bold",
                        color: theme.palette.color.PortfolioCardTitle,
                        textDecoration: "none",
                        fontSize: "14px",
                        "&:hover": {
                          color: "#ff8800",
                          cursor: "pointer",
                        },
                      }}
                    >
                      {item?.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        color: theme.palette.color.PortfolioCardDescription,
                        fontSize: "12px",
                      }}
                    >
                      {item?.description}
                    </Typography>
                    <br />
                    <Typography
                      variant="body2"
                      style={{
                        color: theme.palette.color.PortfolioCardDate,
                        fontSize: "12px",
                      }}
                    >
                      {moment(item?.timestamp.toDate().toISOString()).format(
                        "YYYY-MM-DD-HH:mm:ss a"
                      )}
                    </Typography>
                  </Box>

                  <Typography
                    variant="body2"
                    style={{
                      backgroundColor: catagoryColor.background,
                      color: catagoryColor.text,
                      fontSize: "12px",
                      width: "fit-content",
                      padding: "4px 8px",
                      borderRadius: "5px",
                    }}
                  >
                    {item?.category}
                  </Typography>
                </CardContent>
                <CardActions
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    height: "50px",
                    backgroundColor: theme.palette.color.PortfolioCardFooter,
                  }}
                >
                  <Box>
                    <CopyToClipboard text={`${current_url}/${item.id}`}>
                      <Button
                        size="small"
                        sx={{
                          color: "#ff8800",
                          "&:hover": {
                            color: "#ff8800",
                            backgroundColor: "#000",
                          },
                        }}
                        onClick={notifyCopied}
                      >
                        Share
                      </Button>
                    </CopyToClipboard>

                    <Button
                      size="small"
                      component={Link}
                      to={`/portfolio/${item.id}`}
                      state={{ id: item.id }}
                      sx={{
                        color: "#ff8800",
                        textDecoration: "none",
                        "&:hover": {
                          color: "#ff8800",
                          backgroundColor: "#000",
                        },
                      }}
                    >
                      Read More
                    </Button>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {user && user.roles.admin === true ? (
                      <>
                        {item?.trending === "yes" ? (
                          <>
                            <button
                              onClick={() =>
                                // console.log(item.id)

                                {
                                  const trendingRef = doc(
                                    db,
                                    "portfolio",
                                    item.id
                                  );
                                  if (item.trending?.includes("yes")) {
                                    // console.log("no");
                                    updateDoc(trendingRef, {
                                      trending: "no",
                                    })
                                      .then((response) => {
                                        // alert("updated");
                                      })
                                      .catch((error) => {
                                        console.log(error.message);
                                      });
                                  }
                                }
                              }
                              style={{
                                background: "none",
                                color: "inherit",
                                border: "none",
                                padding: 0,
                                font: "inherit",
                                cursor: "pointer",
                                outline: "inherit",
                              }}
                            >
                              <img
                                src={trending_yes}
                                width="20px"
                                height="20px"
                                alt=""
                              />
                            </button>
                          </>
                        ) : (
                          <></>
                        )}

                        {item?.trending === "no" ? (
                          <>
                            <button
                              onClick={() => {
                                const trendingRef = doc(db, "blogs", item.id);
                                if (item.trending?.includes("no")) {
                                  // console.log("no");
                                  updateDoc(trendingRef, {
                                    trending: "yes",
                                  })
                                    .then((response) => {
                                      // alert("updated");
                                    })
                                    .catch((error) => {
                                      console.log(error.message);
                                    });
                                } else {
                                  console.log("no");
                                  updateDoc(trendingRef, {
                                    trending: "yes",
                                  })
                                    .then((response) => {
                                      // alert("updated");
                                    })
                                    .catch((error) => {
                                      console.log(error.message);
                                    });
                                }
                              }}
                              style={{
                                background: "none",
                                color: "inherit",
                                border: "none",
                                padding: 0,
                                font: "inherit",
                                cursor: "pointer",
                                outline: "inherit",
                              }}
                            >
                              <img
                                src={trending_no}
                                width="20px"
                                height="20px"
                                alt=""
                              />
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    <Box
                      // onClick={() => handleClap(blog?.id)}
                      sx={{
                        position: "relative",
                        width: "fit-content",
                        height: "fit-content",
                        "&:hover": { cursor: "pointer" },
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginRight:
                          user && user.role === "admin" ? "0px" : "5px",
                      }}
                    >
                      <FaHandsWash color="#9fef00" size={30} />
                      <Typography
                        sx={{
                          fontSize: "18px",
                          zIndex: 12,
                          color: "#63c0f5",

                          marginLeft:
                            user && user.role === "admin" ? "0px" : "10px",

                          fontWeight: "bold",
                        }}
                      >
                        {item?.clappedBy?.length + item?.clappedByIp?.length}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        position: "relative",
                        width: "fit-content",
                        height: "fit-content",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginRight:
                          user && user.role === "admin" ? "0px" : "5px",
                      }}
                    >
                      <FaCommentAlt color="#FF8800" size={25} />
                      <Typography
                        sx={{
                          fontSize: "18px",
                          zIndex: 12,
                          color: "#63c0f5",

                          marginLeft:
                            user && user.role === "admin" ? "0px" : "10px",

                          fontWeight: "bold",
                        }}
                      >
                        {item?.comments !== undefined
                          ? item?.comments?.length
                          : 0}
                      </Typography>
                    </Box>
                    {user && user.roles.admin === true ? (
                      <>
                        <Link
                          to={`/poupdate/${item.id}`}
                          sx={{
                            "&:hover": {
                              color: "#ff8800",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <EditIcon />
                        </Link>

                        <DeleteIcon
                          onClick={() => {
                            handleDelete(item.id);
                          }}
                          sx={{
                            color: "#FF8800",
                            "&:hover": {
                              color: "#ff8800",
                              cursor: "pointer",
                            },
                          }}
                        />
                      </>
                    ) : null}
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PaginationComponent
          postsPerPage={postsPerPage}
          setPostsPerPage={setPostsPerPage}
          totalPosts={totalPosts}
          paginate={paginate}
          currentPage={currentPage}
        />
      </Box>
    </Box>
  );
};

export default PortfolioSection;

const styles = {
  cardStyle: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    maxHeight: "400px",
    minWidth: "300px",
    transitionDuration: "0.3s",
    borderRadius: "4px",
    transition: "all 250ms linear",
    boxShadow: 10,
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
    // marginBottom: "10px",
  },
  imgStyle: {
    transition: "all 250ms ease-in-out",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
    },
  },
};
