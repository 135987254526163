/* eslint-disable react/jsx-pascal-case */
import { Box, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import { toast } from "react-toastify";
import { UserAuth } from "../../../../context/AuthContext";
import { db, storage } from "../../../../firebase";
import "./AddProjectPost.scss";
import {   MdEditor  
 } from "md-editor-rt";
import "md-editor-rt/lib/style.css";

const useStyles = makeStyles(() => ({
  dropdown: {
    "&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot": {
      padding: "0px !important",
      fontSize: "14px",
      color: "black",
      fontWeight: 500,
      width: "fit-content",
      minWidth: "500px",
      height: "60px",
      backgroundColor: "#DDD",
    },
    "& .MuiAutocomplete-endAdornment": {
      right: "0px !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#53ad32",
    },
    "&.MuiAutocomplete-fullWidth": true,
  },
}));

const initialState = {
  title: "",
  author: "",
  tags: [],
  trending: "no",
  category: "",
  description: "",
  content: [],
  timestamp: "",
  portSwigger_level: "",
  portSwigger_lab: "",
  securityType: [],
};
var tinyCloudKey = "";






const AddProjectPost = ({ inline, children = [], className, ...props }) => {
  const { user } = UserAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  const classes = useStyles();

  if (location.pathname.includes("update")) {
    //console.log("Location is Update", location.pathname);
  } else {
    // console.log("Location is Add", location.pathname);
  }
  const editorRef = useRef(null);

  const [form, setForm] = useState(initialState);
  const categories = ["Hardware", "Software", "Graphics", "Videos", "3D"];

  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(null);
  const [editorData, setEditorData] = useState("");
  const [text, setText] = useState("");

  const [settings, setSettings] = useState([]);

  const {
    title,
    author,
    tags,
    category,
    trending,
    description,
    content,
    timestamp,
  } = form;
  useEffect(() => {
    const uploadFile = () => {
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setProgress(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
            toast.info("Image uploaded successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setForm((prev) => ({ ...prev, imgUrl: downloadUrl }));
          });
        }
      );
    };

    file && uploadFile();
  }, [file]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleTags = (tags) => {
    setForm({ ...form, tags });
  };

  
  const handleTrending = (e) => {
    setForm({ ...form, trending: e.target.value });
  };

 
  const [selectedCat, setSelectedCat] = useState("");

  const onCategoryChange = (e) => {
    setForm({ ...form, category: e.target.value });
    setSelectedCat(e.target.value);
  };
  


  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log("Adding Blog");
    if ( tags && title && description && trending) {
      if (!id) {
        try {
          await addDoc(collection(db, "projects"), {
            ...form,
            timestamp: serverTimestamp(),
            userId: user.uid,
            content: editorData,
            likedBy: [],
            likedByIp: [],
            clappedBy: [],
            clappedByIp: [],
          });
          toast.success("Projects has been created", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        } catch (err) {
          // console.log(err);
        }
      } else {
        try {
          await updateDoc(doc(db, "projects", id), {
            ...form,
            timestamp: serverTimestamp(),
            userId: user.uid,
            content: editorData,
          });
          toast.success("Projects has been updated", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        } catch (err) {
          // console.log(err);
        }
      }
    } else {
      return toast.error("All fields need to be used", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }

    navigate("/projectlist");
  };

  //date
  var str = "";
  if (timestamp > 0) {
    str = new Date(timestamp.seconds * 1000);
  }
  const date_formated_timestamp = moment(str).format(
    "dddd, MMMM Do YYYY, h:mm:ss a"
  );

  useEffect(() => {
    const q = query(collection(db, "settings"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let settingsArr = [];
      querySnapshot.forEach((doc) => {
        settingsArr.push({ ...doc.data(), id: doc.id });
      });
      setSettings(settingsArr);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  settings.forEach((x) => {
    tinyCloudKey = x.key;
  });

  return (
    <>
      <Helmet>
        <title>XsiSec.com | Bloglist Add ProjectPost</title>
        <meta
          name="description"
          content="Contact us if you have any question or requests"
        />
      </Helmet>
      <Container maxWidth="lg" style={{ width: "100%" }}>
        {/* <Particle /> */}
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            position: "relative",
            zIndex: 1,
            textAlign: "center",
            paddingTop: "120px",
          }}
        >
          <Typography
            style={{ fontSize: "22px", color: "#ff9100", fontWeight: "bold" }}
          >
            {id ? "Update Projectpost" : "Create ProjectPost"}
          </Typography>

          <div>
            {id ? (
              <input
                style={{ marginBottom: 10 }}
                disabled
                type="text"
                className="form-control input-text-box"
                name="timestamp"
                value={date_formated_timestamp}
                onChange={handleChange}
              />
            ) : null}
          </div>

          <form
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
            onSubmit={handleSubmit}
          >
            <input
              style={{ width: "50%", marginBottom: 10 }}
              type="text"
              className="form-control input-text-box"
              placeholder="author"
              name="author"
              value={author}
              defaultValue="XsiSec"
              onChange={handleChange}
            />

            <input
              style={{ width: "50%" }}
              type="text"
              className="form-control input-text-box"
              placeholder="title"
              name="title"
              value={title}
              onChange={handleChange}
            />

            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                height: 500,
                width: "100%",
                columnGap: 10,
                marginTop: "20px",
              }}
            >
              <Box style={{ flex: 1 }}>
              <MdEditor
                   modelValue={text}
                   theme="light"
                   codeTheme='github'
                   ref={editorRef}
                   editorId="md-prev"
                   previewTheme={props.previewTheme}
                   htmlPreview
                   language='en-US'
                   toolbarsExclude={['github']}
                   noPrettier
                   tabWidth={4}
                   showCodeRowNumber
                  //  katex={katex}
                   tableShape={[10, 10]}
                   // noMermaid
                   // placeholder="placeholder"
                   // noKatex
                   // noHighlight
                   // sanitize={(h) => `<a href="#">aaa</a>${h}`}
                   // scrollAuto={false}
                   // noIconfont
                   // codeStyleReverse={false}
                   // codeStyleReverseList={['mk-cute']}
                   autoFocus
                   // disabled
                   // readOnly
                   // maxLength={10}
                   // autoDetectCode
                   // onHtmlChanged={console.log}
                   toolbars={[
                    'bold',
                    'underline',
                    'italic',
                    'strikeThrough',
                    '-',
                    'title',
                    'sub',
                    'sup',
                    'quote',
                    'unorderedList',
                    'orderedList',
                    'task',
                    '-',
                    'codeRow',
                    'code',
                    'link',
                    'image',
                    'table',
                    'mermaid',
                    'katex',
                    '-',
                    'revoke',
                    'next',
                    0,
                    1,
                    2,
                    '=',
                    'prettier',
                    'pageFullscreen',
                    'fullscreen',
                    'preview',
                    'htmlPreview',
                    'catalog',
                    'github'
                  ]}
                   onChange={(newValue) => {
                     setText(newValue);
                     setEditorData(newValue);
                   }}
                />
              </Box>
            </Box>

        

            <br />

            <TagsInput
              value={tags}
              onChange={handleTags}
              name="tags"
              placeHolder="Enter tags"
            />

            <div className="col-12 py-3">
              <p className="trending">is the post trending?</p>
              <div className="form-check-inline mx-2">
                <input
                  type="radio"
                  className="form-check-input"
                  value="yes"
                  name="radioOption"
                  checked={trending === "yes"}
                  onChange={handleTrending}
                />
                <label htmlFor="radioOption" className="form-check-label">
                  Yes&nbsp;
                </label>
                <input
                  type="radio"
                  className="form-check-input"
                  value="no"
                  name="radioOption"
                  checked={trending === "no"}
                  onChange={handleTrending}
                />
                <label htmlFor="radioOption" className="form-check-label">
                  No
                </label>
              </div>
            </div>
            <div className="col-12 py-3">
            <select
              value={category}
              onChange={onCategoryChange}
              className="catg-dropdown"
            >
              <option>Category</option>
              {categories.map((option, index) => (
                <option value={option || ""} key={index}>
                  {option}
                </option>
              ))}
            </select>
          </div>

       
            <div className="col-12 py-3">
              <textarea
                className="form-control description-box"
                placeholder="description"
                value={description}
                name="description"
                onChange={handleChange}
              />
            </div>
            {id && <img src={form.imgUrl} alt={form.title} />}
            <div className="mb-3">
              <input
                type="file"
                className="form-control"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </div>
            <div style={{ marginBottom: 150 }}>
              <button
                style={{
                  backgroundColor: "#1a2332",
                  color: "#fff",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "20px",
                }}
                type="submit"
                disabled={progress !== null && progress < 100}
              >
                {id ? "Updating" : "Publish"}
              </button>
            </div>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default AddProjectPost;
