import { StrictMode } from "react";
import * as ReactDOMClient from 'react-dom/client';
import "./index.css";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { AuthContextProvider } from "./context/AuthContext";


const root = ReactDOMClient.createRoot(document.getElementById('root'));
const theme = createTheme({
  components: {

  },
});
root.render(
  <ThemeProvider theme={theme}>
    <StrictMode>
      <AuthContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthContextProvider>
    </StrictMode>
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
