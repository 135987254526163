import bg_1 from "../../Assets/project/1.jpg";
import bg_2 from "../../Assets/project/2.jpg";
import bg_3 from "../../Assets/project/3.jpg";
import bg_4 from "../../Assets/project/4.jpg";
import bg_5 from "../../Assets/project/5.jpg";
import bg_6 from "../../Assets/project/6.jpg";
import bg_7 from "../../Assets/project/7.jpg";
import bg_8 from "../../Assets/project/8.jpg";
import bg_9 from "../../Assets/project/9.jpg";
import bg_10 from "../../Assets/project/10.jpg";
import bg_11 from "../../Assets/project/11.jpg";
import bg_12 from "../../Assets/project/12.jpg";
import bg_13 from "../../Assets/project/13.jpg";
import bg_14 from "../../Assets/project/14.jpg";
import bg_15 from "../../Assets/project/15.jpg";
import bg_16 from "../../Assets/project/16.jpg";
import bg_17 from "../../Assets/project/17.jpg";


export const images = [
  bg_1,
  bg_2,
  bg_3,
  bg_4,
  bg_5,
  bg_6,
  bg_7,
  bg_8,
  bg_9,
  bg_10,
  bg_11,
  bg_12,
  bg_13,
  bg_14,
  bg_15,
  bg_16,
  bg_17,  

];
