import "./AddBlogPost.scss";

/* eslint-disable react/jsx-pascal-case */
import { Box, Container, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../../../../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import { Helmet } from "react-helmet";
import { TagsInput } from "react-tag-input-component";
import TextField from "@mui/material/TextField";
import { UserAuth } from "../../../../context/AuthContext";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { toast } from "react-toastify";

import { MdEditor } from "md-editor-rt";
import "md-editor-rt/lib/style.css";

const useStyles = makeStyles(() => ({
  dropdown: {
    "&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot": {
      padding: "0px !important",
      fontSize: "14px",
      color: "black",
      fontWeight: 500,
      width: "fit-content",
      minWidth: "500px",
      height: "60px",
      backgroundColor: "#DDD",
    },
    "& .MuiAutocomplete-endAdornment": {
      right: "0px !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#53ad32",
    },
    "&.MuiAutocomplete-fullWidth": true,
  },
}));

const initialState = {
  title: "",
  author: "XsiSec",
  tags: [],
  trending: "no",
  category: "",
  description: "",
  content: [],
  timestamp: "",
  portSwigger_level: "",
  portSwigger_lab: "",
  securityType: [],
};
var tinyCloudKey = "";

const categories = [
  "HTB Academy",
  "HackTheBox",
  "PortSwigger",
  "Knowledge",
  "TryHackMe",
  "Tutorials",
  "Security",
  "BugBounty",
  "Other",
];

const Portswigger_securityTypes = [
  "SQL Injection",
  "Cross-site scripting",
  "Cross-site request forgery (CSRF)",
  "Clickjacking",
  "DOM-based vulnerabilites",
  "Cross-origin resource sharing (CORS)",
  "XML external entity (XXE) injection",
  "Server-side request forgery (SSRF)",
  "HTTP request smuggling",
  "OS command injection",
  "Server-side template injection",
  "Path traversal",
  "Access control vulnerabilities",
  "Authentication",
  "WebSockets",
  "Web cache poisoning",
  "Insecure deserialization",
  "Information disclosure",
  "Business logic vulnerabilities",
  "HTTP Host header attacks",
  "OAuth authentication",
  "File upload vulnerabilities",
  "JWT",
  "Essential skills",
  "Prototype pollution",
  "GraphQL API vulnerabilities",
  "Race conditions",
  "NoSQL injection",
  "API testing",
  "Web LLM attacks",
  "XXE",
  "SQLi",
  "Reflected-SQLi",
  "Blind-SQLi",
  "XSS",
  "Brute-Force",
  "Command-Injection",
  
  "IDOR",
  "Reverse-Shell",
  "Buffer overflow",
  "Out-of-bounds",
  "Unrestricted File Upload",
  "MitM Attack",
  "Cryptojacking",
  "Privelege Escalation",
];

const portSwigger_levels = ["Apprentice", "Practitioner", "Expert", "Master"];
const portSwigger_labs = [
  "Lab#1",
  "Lab#2",
  "Lab#3",
  "Lab#4",
  "Lab#5",
  "Lab#6",
  "Lab#7",
  "Lab#8",
  "Lab#9",
  "Lab#10",
  "Lab#11",
  "Lab#12",
  "Lab#13",
  "Lab#14",
  "Lab#15",
  "Lab#16",
];

const AddBlogpost = ({ inline, children = [], className, ...props }) => {
  const { user } = UserAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  const classes = useStyles();

  const [text, setText] = useState("");

  if (location.pathname.includes("update")) {
    //console.log("Location is Update", location.pathname);
  } else {
    // console.log("Location is Add", location.pathname);
  }
  const editorRef = useRef(null);

  const [form, setForm] = useState(initialState);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(null);
  const [editorData, setEditorData] = useState("");
  const [showPortSwiggerLevel, setPortSwiggerLevel] = useState(false);
  const [showPortSwiggerLab, setPortSwiggerLab] = useState(false);
  const [showSecurityType, setSecurityType] = useState(false);

  const {
    title,
    author,
    tags,
    category,
    trending,
    description,
    content,
    timestamp,
    portSwigger_level,
    portSwigger_lab,
    securityType,
  } = form;
  useEffect(() => {
    const uploadFile = () => {
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setProgress(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
            toast.info("Image uploaded successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setForm((prev) => ({ ...prev, imgUrl: downloadUrl }));
          });
        }
      );
    };

    file && uploadFile();
  }, [file]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleTags = (tags) => {
    setForm({ ...form, tags });
  };

  const handleSecurityType = (securityType) => {
    setForm({ ...form, securityType });
  };
  const handleTrending = (e) => {
    setForm({ ...form, trending: e.target.value });
  };

  const [selectedCat, setSelectedCat] = useState("");
  const [selectedCatB, setSelectedCatB] = useState("");
  const [selectedCatC, setSelectedCatC] = useState("");

  const [secSel, setSecSel] = useState(null);

  const onCategoryChange = (e) => {
    setForm({ ...form, category: e.target.value });
    setSelectedCat(e.target.value);

    if (e.target.value === "PortSwigger") {
      setPortSwiggerLevel(true);
      setPortSwiggerLab(true);
      setSecurityType(true);
    } else {
      setPortSwiggerLevel(false);
      setPortSwiggerLab(false);
      setSecurityType(false);
    }
  };

  const onCategoryChangeB = (e) => {
    setForm({ ...form, portSwigger_level: e.target.value });
    setSelectedCatB(e.target.value);
  };

  const onCategoryChangeC = (e) => {
    setForm({ ...form, portSwigger_lab: e.target.value });
    setSelectedCatC(e.target.value);
  };

  const PortSwigger_dropdown = () => (
    <div className="col-12 py-3">
      <select
        value={portSwigger_level}
        onChange={onCategoryChangeB}
        className="catg-dropdown"
      >
        <option>Level</option>
        {portSwigger_levels.map((option, index) => (
          <option value={option || ""} key={index}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );

  const PortSwigger_Lab_dropdown = () => (
    <div className="col-12 py-3">
      <select
        value={portSwigger_lab}
        onChange={onCategoryChangeC}
        className="catg-dropdown"
      >
        <option>Lab</option>
        {portSwigger_labs.map((option, index) => (
          <option value={option || ""} key={index}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log("Adding Blog");
    if (category && tags && title && description && trending) {
      if (!id) {
        try {
          await addDoc(collection(db, "blogs"), {
            ...form,
            timestamp: serverTimestamp(),
            userId: user.uid,
            content: editorData,
            likedBy: [],
            likedByIp: [],
            clappedBy: [],
            clappedByIp: [],
          });
          toast.success("Blog has been created", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        } catch (err) {
          // console.log(err);
        }
      } else {
        try {
          await updateDoc(doc(db, "blogs", id), {
            ...form,
            timestamp: serverTimestamp(),
            userId: user.uid,
            content: editorData,
          });
          toast.success("Blog has been updated", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        } catch (err) {
          // console.log(err);
        }
      }
    } else {
      return toast.error("All fields need to be used", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }

    navigate("/bloglist");
  };

  //date
  var str = "";
  if (timestamp > 0) {
    str = new Date(timestamp.seconds * 1000);
  }
  const date_formated_timestamp = moment(str).format(
    "dddd, MMMM Do YYYY, h:mm:ss a"
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>XsiSec.com | Bloglist Add Blogpost</title>
        <meta
          name="description"
          content="Contact us if you have any question or requests"
        />
      </Helmet>
      <Container maxWidth="lg" style={{ width: "100%" }}>
        {/* <Particle /> */}
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            position: "relative",
            zIndex: 1,
            textAlign: "center",
            paddingTop: "120px",
          }}
        >
          <Typography
            style={{ fontSize: "22px", color: "#ff9100", fontWeight: "bold" }}
          >
            {id ? "Update blog" : "Create blogpost"}
          </Typography>

          <div>
            {id ? (
              <input
                style={{ marginBottom: 10 }}
                disabled
                type="text"
                className="form-control input-text-box"
                name="timestamp"
                value={date_formated_timestamp}
                onChange={handleChange}
              />
            ) : null}
          </div>

          <form
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
            onSubmit={handleSubmit}
          >
            <input
              style={{ width: "50%", marginBottom: 10 }}
              type="text"
              className="form-control input-text-box"
              placeholder="author"
              name="author"
              value={author}
              defaultValue="XsiSec"
              onChange={handleChange}
            />

            <input
              style={{ width: "50%" }}
              type="text"
              className="form-control input-text-box"
              placeholder="title"
              name="title"
              value={title}
              onChange={handleChange}
            />

            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                height: 500,
                width: "100%",
                columnGap: 10,
                marginTop: "20px",
              }}
            >
              <Box style={{ flex: 1 }}>
                <MdEditor
                  modelValue={text}
                  theme="light"
                  codeTheme="github"
                  ref={editorRef}
                  editorId="md-prev"
                  previewTheme={props.previewTheme}
                  htmlPreview
                  language="en-US"
                  toolbarsExclude={["github"]}
                  noPrettier
                  tabWidth={4}
                  showCodeRowNumber
                  //  katex={katex}
                  tableShape={[10, 10]}
                  // noMermaid
                  // placeholder="placeholder"
                  // noKatex
                  // noHighlight
                  // sanitize={(h) => `<a href="#">aaa</a>${h}`}
                  // scrollAuto={false}
                  // noIconfont
                  // codeStyleReverse={false}
                  // codeStyleReverseList={['mk-cute']}
                  autoFocus
                  // disabled
                  // readOnly
                  // maxLength={10}
                  // autoDetectCode
                  // onHtmlChanged={console.log}
                  toolbars={[
                    "bold",
                    "underline",
                    "italic",
                    "strikeThrough",
                    "-",
                    "title",
                    "sub",
                    "sup",
                    "quote",
                    "unorderedList",
                    "orderedList",
                    "task",
                    "-",
                    "codeRow",
                    "code",
                    "link",
                    "image",
                    "table",
                    "mermaid",
                    "katex",
                    "-",
                    "revoke",
                    "next",
                    0,
                    1,
                    2,
                    "=",
                    "prettier",
                    "pageFullscreen",
                    "fullscreen",
                    "preview",
                    "htmlPreview",
                    "catalog",
                    "github",
                  ]}
                  onChange={(newValue) => {
                    setText(newValue);
                    setEditorData(newValue);
                  }}
                />
              </Box>
            </Box>

            <br />
            <Autocomplete
              multiple
              size="medium"
              id="tags-filled"
              className={classes.dropdown}
              onChange={(e, value) =>
                setForm((form) => ({ ...form, securityType: value }))
              }
              options={Portswigger_securityTypes.map((option) => option)}
              // defaultValue={[VulnerabilityTypes[13].title]}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder="Vulnerability-type..."
                />
              )}
            />

            <br />

            <TagsInput
              value={tags}
              onChange={handleTags}
              name="tags"
              placeHolder="Enter tags"
            />

            <div className="col-12 py-3">
              <p className="trending">is the post trending?</p>
              <div className="form-check-inline mx-2">
                <input
                  type="radio"
                  className="form-check-input"
                  value="yes"
                  name="radioOption"
                  checked={trending === "yes"}
                  onChange={handleTrending}
                />
                <label htmlFor="radioOption" className="form-check-label">
                  Yes&nbsp;
                </label>
                <input
                  type="radio"
                  className="form-check-input"
                  value="no"
                  name="radioOption"
                  checked={trending === "no"}
                  onChange={handleTrending}
                />
                <label htmlFor="radioOption" className="form-check-label">
                  No
                </label>
              </div>
            </div>
            <div className="col-12 py-3">
              <select
                value={category}
                onChange={onCategoryChange}
                className="catg-dropdown"
              >
                <option>Category</option>
                {categories.map((option, index) => (
                  <option value={option || ""} key={index}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div>
              {showPortSwiggerLevel ? <PortSwigger_dropdown /> : null}
              {showPortSwiggerLab ? <PortSwigger_Lab_dropdown /> : null}
            </div>

            {selectedCat === "HackTheBox" && <></>}

            <div className="col-12 py-3">
              <textarea
                className="form-control description-box"
                placeholder="description"
                value={description}
                name="description"
                onChange={handleChange}
              />
            </div>
            {id && <img src={form.imgUrl} alt={form.title} />}
            <div className="mb-3">
              <input
                type="file"
                className="form-control"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </div>
            <div style={{ marginBottom: 150 }}>
              <button
                style={{
                  backgroundColor: "#1a2332",
                  color: "#fff",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "20px",
                }}
                type="submit"
                disabled={progress !== null && progress < 100}
              >
                {id ? "Updating" : "Publish"}
              </button>
            </div>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default AddBlogpost;
