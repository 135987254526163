import React from "react";
import "./Tags.scss";
import { useTheme } from "@mui/material";
const Tags = ({ tags, filterByTag, direction }) => {
  // var randomColor = Math.floor(Math.random()*16777215).toString(16);
  // console.log("#"+randomColor)

  const first10Tags = tags?.slice(0, 9);
  const restOfTheTags = tags?.slice(10, 19);
  // function getRandomColor() {
  //   var letters = "0123456789ABCDEF";
  //   var color = "#";
  //   for (var i = 0; i < 6; i++) {
  //     color += letters[Math.floor(Math.random() * 16)];
  //   }
  //   return color;
  // }

  const theme = useTheme();

  return (
    <div
      style={{
        marginLeft: "5%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <hr></hr>
      <hr></hr>
      <hr></hr>

      <div style={{ display: "flex", flexDirection: "row" }}>
        {tags?.length <= 10 ? (
          <div className={direction === "row" ? "tags__row" : "tags"}>
            {tags?.map((tag, index) => (
              <button
                className="tag"
                style={{
                  color: theme.palette.color.yellow,
                  background: theme.palette.color.lightBlueForTags,
                }}
                key={index}
                value={tag}
                onClick={(e) => filterByTag(e.target.value)}
              >
                {tag}
              </button>
            ))}
          </div>
        ) : (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              className={direction === "row" ? "tags__row" : "tags"}
              style={{ flexGrow: 1 }}
            >
              {first10Tags?.map((tag, index) => (
                <button
                  className="tag"
                  style={{
                    color: theme.palette.color.yellow,
                    background: theme.palette.color.lightBlueForTags,
                  }}
                  key={index}
                  value={tag}
                  onClick={(e) => filterByTag(e.target.value)}
                >
                  {tag}
                </button>
              ))}
            </div>
            <div
              className={direction === "row" ? "tags__row" : "tags"}
              style={{ flexGrow: 1 }}
            >
              {restOfTheTags?.map((tag, index) => (
                <button
                  className="tag"
                  style={{
                    color: theme.palette.color.yellow,
                    background: theme.palette.color.lightBlueForTags,
                  }}
                  key={index}
                  value={tag}
                  onClick={(e) => filterByTag(e.target.value)}
                >
                  {tag}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
      <button
        className="tag"
        style={{
          backgroundColor: theme.palette.color.limeGreenForReset,
          color: "#1a2332",
        }}
        value={"reset"}
        onClick={(e) => filterByTag(e.target.value)}
      >
        Reset
      </button>
    </div>
  );
};

export default Tags;
