
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDuzVp4qVvqrKYs1edeZu3dttPH8uwOGKs",
  authDomain: "reading-list-eb1dc.firebaseapp.com",
  projectId: "reading-list-eb1dc",
  storageBucket: "reading-list-eb1dc.appspot.com",
  messagingSenderId: "519470694933",
  appId: "1:519470694933:web:688f153e5a6a6f5cae841c"
};


const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);
const storage=getStorage(app)

export { db, auth ,storage};



