import React from "react";
import userImg from "../../Assets/programmer.svg";

import { NavLink } from "react-router-dom";
import "./Home.scss";
import HomeCard from "./HomeCard";
import InspirationStack from "./InspirationStack";
import Timeline from "./Timeline";
import Toolstack from "./Toolstack";
import Type from "./Type";


import { Box, Container, Grid, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  headingName: {
    color: theme.palette.color.white,
  },
  aboutCard: {
    color: theme.palette.color.white,
  },
}));

function Home() {

  const classes = useStyles();
  const theme = useTheme();


  return (
    <>
      <Container maxWidth="lg">
        {/* <Particle /> */}
        <Box className="home-section">
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >



            <Box>
              <h1 style={{

                color: theme.palette.color.HomeHeadingColor,

              }}
              >
                I'M
                <strong className="main-name"> XsiSec</strong>
              </h1>

              <div style={{ padding: 5, textAlign: "left", }}>
                <Type />
              </div>
            </Box>
            <Grid container style={{ marginTop: "30px" }} className={classes.aboutCard}>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Box style={{}}>
                  <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
                    Who <strong className="purple">am I?</strong>
                  </h1>
                  <HomeCard />
                  <br />

                  <h1
                    style={
                      // color: "#ff9100" ,
                      {
                        fontSize: "1.2rem",
                        lineHeight: "1.0",
                        // margin: "0 0 0 -40px",
                        fontWeight: "bold",
                        fontFamily:
                          'Monaco, "Bitstream Vera Sans Mono", "Lucida Console", Terminal, monospace',
                        color: theme.palette.color.ReadMoreLinkColour,
                        WebkitFontSmoothing: "antialiased",

                      }
                    }
                  >




                  </h1>
                </Box>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="home-img"
                >
                  <img
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      maxWidth: "400px",
                    }}
                    src={userImg}
                    alt="user"
                  />

                  <h3 className="project-heading">
                    <strong className="purple">Favorite Tools </strong> I use
                  </h3>
                  <Toolstack />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <br/>
          <h4 className={`${classes.headingName} project-heading`}>
            <center>
              <strong className="purple">Inspiration</strong>
            </center>
          </h4>
          <InspirationStack />
        </Box>
        <Timeline />
      </Container>
    </>
  );
}
export default Home;
