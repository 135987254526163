import React from "react";
import "./Timeline.scss";
import moment from "moment";
import { useTheme } from "@mui/material";

const Timeline = () => {
  const theme = useTheme();

  //  console.log(moment().format("MM ddd, YYYY HH:mm:ss a"));

  const timeline_time_1 = "2020-08-04T18:30:00.000Z";
  const converted_time_1 = moment(timeline_time_1).fromNow();

  const timeline_time_2 = "2020-06-04T18:30:00.000Z";
  const converted_time_2 = moment(timeline_time_2).fromNow();

  const timeline_time_3 = "2020-04-04T18:30:00.000Z";
  const converted_time_3 = moment(timeline_time_3).fromNow();
  return (
    <div className="container">
      <div className="timeline">
        <h1 style={{ color: theme.palette.color.white }}>@Angular Cli</h1>
        <div className="timeline-container primary">
          <div className="timeline-icon">
            <i className="far fa-grin-wink"></i>
          </div>
          <div
            className="timeline-body"
            style={{
              background: theme.palette.color.deepLightGreyForTimeline,
              color: theme.palette.color.white,
            }}
          >
            <h4 className="timeline-title">
              <span className="badge">What to use</span>
            </h4>
            When I made this project ...
            <br />I thought of using either <u>Python</u> or <u>Angular Cli</u>.
            <br />
            <br />
            I had lots of different things I wanted to accomplish, for instance
            write the database tables and API services on my own. <br />
            <br />
            Since I write exploits in python I felt at first that this will be
            an easy choice, though I did already know I would struggle with
            several things that might take time.
            <br />
            I did know Angular-cli is one of the most famous frameworks used in
            development. <br />
            <br />I had less experience in the picked framework so regardless
            thought this could be really useful to learn and get much use of
            later on for professional purposes. After several hours in research
            I gave 'Angular-cli a shot.
            <p className="timeline-subtitle">{converted_time_1}</p>
          </div>
        </div>
        <div className="timeline-container danger">
          <div className="timeline-icon">
            <i className="far fa-grin-hearts"></i>
          </div>
          <div className="timeline-body" style={{
              background: theme.palette.color.deepLightGreyForTimeline,
              color: theme.palette.color.white,
            }}>
            <h4 className="timeline-title">
              <span className="badge">Implementation</span>
            </h4>
            <p>
              During the project I wanted to make use of following things:
              <br />
              <b>• API-calls </b>- from domains with complex structure.
              <br />
              <br />
              <b>• Database </b> - Create a own structure etc.
              <br />
              <br />
              <b>• Geographical Data </b> - from various domains.
              <br />
            </p>
            <p className="timeline-subtitle">{converted_time_2}</p>
          </div>
        </div>
        <div className="timeline-container success">
          <div className="timeline-icon">
            <i className="far fa-grin-tears"></i>
          </div>
          <div className="timeline-body" style={{
              background: theme.palette.color.deepLightGreyForTimeline,
              color: theme.palette.color.white,
            }}>
            <h4 className="timeline-title">
              <span className="badge">Inspiration</span>
            </h4>
            <p>
              I have been inspired of:
              <br />•{" "}
              <a href="https://wiki.archlinux.org/index.php/SLiM">SLiM </a>
              <br />•{" "}
              <a href="https://en.wikipedia.org/wiki/Pixel_art">
                Pixel Art
              </a>{" "}
              <br />•{" "}
              <a href="https://en.wikipedia.org/wiki/Pixel_art">
                Nintendo 8 bit
              </a>
            </p>
            <p className="timeline-subtitle">{converted_time_3}</p>
          </div>
        </div>
        <div className="timeline-container warning">
          <div className="timeline-icon">
            <i className="far fa-grimace"></i>
          </div>
          <div className="timeline-body" style={{
              background: theme.palette.color.deepLightGreyForTimeline,
              color: theme.palette.color.white,
            }}>
            <h4 className="timeline-title">
              <span className="badge">Used Scripts</span>
            </h4>
            I had a list of different scripts I used:
            <p />• <a href="https://popper.js.org/">Popper.js</a>
            <p />•{" "}
            <a href="https://vincentgarreau.com/particles.js/">Particle.js</a>
            <p />• <a href="https://camwiegert.github.io/baffle/">Baffle.js</a>
            <p />•{" "}
            <a href="https://daneden.github.io/animate.css/">Animate.js</a>
            <p />•{" "}
            <a href="https://materializecss.com/getting-started.html">
              materialize.js
            </a>
            <p />• <a href="https://textillate.js.org/">textillate.js</a>
            <p />• <a href="http://letteringjs.com/">lettering.js</a>
            <p />• <a href="https://alertifyjs.com/">alertify.js</a>
            <p />• <a href="https://github.com/mattboldt/typed.js/">typed.js</a>
            <p />•{" "}
            <a href="https://developers.google.com/api-client-library/javascript/start/start-js/">
              api.js
            </a>
            <p className="timeline-subtitle">{converted_time_3}</p>
          </div>
        </div>
        <div className="timeline-container">
          <div className="timeline-icon">
            <i className="far fa-grin-beam-sweat"></i>
          </div>
          <div className="timeline-body" style={{
              background: theme.palette.color.deepLightGreyForTimeline,
              color: theme.palette.color.white,
            }}>
            <h4 className="timeline-title">
              <span className="badge">Used Fonts</span>
            </h4>
            <p>
              • <a href="https://fonts.google.com/specimen/Geo">Geo</a> <br />•{" "}
              <a href="https://fonts.google.com/specimen/Montserrat">
                Montserrat
              </a>
              <br />•{" "}
              <a href="https://fonts.google.com/specimen/Russo+One">
                Russo+One
              </a>
              <br />•{" "}
              <a href="https://fonts.google.com/specimen/Orbitron">Orbitron</a>
              <br />•{" "}
              <a href="https://fonts.google.com/specimen/Roboto">Roboto</a>
              <br />• <a href="https://fonts.google.com/specimen/Teko">Teko</a>
              <br />•{" "}
              <a href="https://fonts.google.com/specimen/Varela+Round">
                Varela Round
              </a>
            </p>
            <p className="timeline-subtitle">{converted_time_3}</p>
          </div>
        </div>
        <div className="timeline-container info">
          <div className="timeline-icon">
            <i className="far fa-grin"></i>
          </div>
          <div className="timeline-body" style={{
              background: theme.palette.color.deepLightGreyForTimeline,
              color: theme.palette.color.white,
            }}>
            <p>
              During this project I faced of course issues <br />
              I got good support from:
              <br />
              #Network <a href="https://freenode.net/">Freenode</a> channel{" "}
              <a href="http://irc.netsplit.de/channels/details.php?room=%23angularjs&net=freenode">
                #angularjs
              </a>{" "}
              in
              <a href="https://sv.wikipedia.org/wiki/Internet_Relay_Chat">
                IRC
              </a>
              .
            </p>
            <p className="timeline-subtitle">{converted_time_3}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
