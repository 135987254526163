import "./ChangeHistoryAdmin.scss";

import { Box, Container } from "@mui/material";
import React, { useEffect } from "react";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  serverTimestamp,
} from "firebase/firestore";

import { FaRegTrashAlt } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { UserAuth } from "../../../context/AuthContext";
import { db } from "../../../firebase";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function ChangeHistoryAdmin() {
  const { user } = UserAuth();

  const navigate = useNavigate();

  const [form, setForm] = useState([]);
  const [description, setDescription] = useState("");
  const updated_at_timestamp = serverTimestamp()

  


  function formatDate(date) {
    const formatDate = new Date(
      date.seconds * 1000 + date.nanoseconds / 1000000
    );
    return formatDate.toLocaleTimeString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  // Read todo from firebase
  useEffect(() => {
    const q = query(collection(db, "changehistory"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let dataArr = [];
      querySnapshot.forEach((doc) => {
        dataArr.push({ ...doc.data(), id: doc.id });
      });
      setForm(dataArr);
    });
    return () => unsubscribe();
  }, []);

  // Delete todo
  const deleteTodo = async (id) => {
    await deleteDoc(doc(db, "changehistory", id));
  };

  const createTodo = async (e) => {
    e.preventDefault(e);
    await addDoc(collection(db, "changehistory"), {
      description: description,
      timestamp: serverTimestamp(),
    });
  };

  return (
    <>
      <Helmet>
        <title>XsiSec.com | Add Settings</title>
        <meta name="Change History" content="Change-history" />
      </Helmet>
      <Container maxWidth="lg" style={{ width: "100%" }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            position: "relative",
            zIndex: 1,
            textAlign: "center",
            paddingTop: "120px",
          }}
        >
          <p />

          <form onSubmit={createTodo}>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className={description}
              type="text"
              placeholder="Add change history"
              style={{
                width: "600px",
              }}
            />
            <p />

            <button>Add</button>
          </form>

          <ul>
            {form.map((todo, index) => (
              <>
                <li className="">
                  <br />
                  <p>
                    Description:
                    {todo?.description}
                  </p>

                  <button onClick={() => deleteTodo(todo.id)}>
                    {<FaRegTrashAlt />}
                  </button>
                </li>
              </>
            ))}
          </ul>
          {form.length < 1 ? null : (
            <p>{` [${form.length}]  items in change-history.`}</p>
          )}
        </Box>
      </Container>
    </>
  );
}
export default ChangeHistoryAdmin;
