import { Box } from "@mui/material";
import React from "react";
import { Navbar } from "react-bootstrap";
import Footer from "../Components/Footer/Footer";
import NavBar from "../Components/Navbar/Navbar";
import { UserAuth } from "../context/AuthContext";

const AppLayout = ({ children }, props) => {
  const { user, logout, loadingHome, activePage } = UserAuth();
  return (
    <Box>
      {activePage === "home" ? loadingHome ? null : "" : ""}
      <Box sx={{ minHeight: "100vh" }}>{children}</Box>
      {activePage === "home" ? loadingHome ? null : <Footer /> : <Footer />}
    </Box>
  );
};

export default AppLayout;
