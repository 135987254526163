import {
  Box, Pagination,
  PaginationItem, useTheme
} from "@mui/material";
import PostsPerPage from "./PostsPerPage";

const PaginationComponent = ({
  postsPerPage,
  setPostsPerPage,
  totalPosts,
  paginate,
  currentPage,
}) => {
  // console.log("Posts per page in pagination", postsPerPage);
  // console.log("Total Posts in pagination", totalPosts);
  // console.log("Buttons to be rendered", Math.ceil(totalPosts / postsPerPage));

  const pageNumbers = Math.ceil(totalPosts / postsPerPage);

  function handleChange(event, value) {
    console.log("Button clicked", value);
    paginate(value);
  }

  const theme = useTheme();

  return (
    <Box style={styles.main}>
      <Pagination
        variant="outlined"
        shape="rounded"
        showFirstButton
        showLastButton
        count={pageNumbers}
        page={currentPage}
        onChange={handleChange}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            sx={{
              backgroundColor: theme.palette.color.lightGrey,
              color: theme.palette.color.white,
              "&.Mui-selected": {
                backgroundColor: "#9fef00",
                color: "rgba(65, 66, 65, 0.6705882353)",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#9fef00",
                  filter: "brightness(1.2)",
                },
              },
              "&:hover": {
                backgroundColor: "#111927",
                filter: "brightness(2)",
              },
            }}
          />
        )}
      />
      <PostsPerPage
        postsPerPage={postsPerPage}
        setPostsPerPage={setPostsPerPage}
        totalPosts={totalPosts}
      />
    </Box>
  );
};

const styles = {
  main: {
    height: "50px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
  },
  pageBtn: {
    width: "50px",
    height: "40px",
    border: "none",
    backgroundColor: "yellowgreen",
    margin: "0 5px",
  },
  pageBtnActive: {
    width: "50px",
    height: "40px",
    border: "none",
    backgroundColor: "blue",
    margin: "0 5px",
  },
  pageBtnLeftRight: {
    width: "50px",
    height: "40px",
    border: "none",
    backgroundColor: "yellowgreen",
    margin: "0 5px",
    borderRadius: 0,
  },
};

export default PaginationComponent;
