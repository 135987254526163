import { Box, Button, Card, CardActions, Typography } from "@mui/material";
import React from "react";
import Carousel from "react-material-ui-carousel";
import { Link } from "react-router-dom";
import "./Trending.scss";

const Trending = ({ blogs }) => {

  return (
    <div style={{ width: "100%" }}>

      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div style={{ width: "100%", maxWidth: "600px" }}>
          <Carousel
            navButtonsAlwaysVisible={true}
            swipe={true}
            indicatorContainerProps={{
              style: {
                marginBottom:"-50px",
                backgroundColor: "#1a2332",
                borderRadius: 0,
              },
            }}
            navButtonsProps={{
              // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
              style: {
                backgroundColor: "#1a2332",
                borderRadius: 0,
              },
            }}
          >
            {blogs?.slice(0, 3).map((item) => (
              <Card
                component={Link}
                to={`/blog/${item.id}`}
                state={{ id: item.id }}
                style={styles.cardStyle}
                key={item?.id}
              >
                <div
                  className="card__content"
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    background: `linear-gradient(to bottom, rgba(0,0,0,.5), rgba(0,0,0,.5)),url("${item?.image_bg}")`,
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h7"
                    component="div"
                    sx={{
                      fontWeight: "bold",
                      color: "#fff",
                      fontSize: "1.5rem",
                      textAlign: "center",
                      "&:hover": {
                        color: "#ff8800",
                        cursor: "pointer",
                      },
                    }}
                  >
                    {item?.title}
                  </Typography>
                </div>
                <CardActions
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    position: "relative",
                    zIndex: 5,
                    height: "60px",
                    backgroundColor: "#111927",
                  }}
                >
                  <Box>
                    <Button
                      size="small"
                      component={Link}
                      to={`/blog/${item.id}`}
                      state={{ id: item.id }}
                      sx={{
                        color: "#ff8800",
                        textDecoration: "none",
                        "&:hover": {
                          color: "#ff8800",
                          backgroundColor: "#000",
                        },
                      }}
                    >
                      Read More
                    </Button>
                  </Box>
                </CardActions>
              </Card>
            ))}
          </Carousel>
          <br/>
          <Typography
        sx={{
          fontWeight: "600",
          fontFamily: "'Titillium Web', sans-serif",
          position: "relative",
          fontSize: "20px",
          lineHeight: "20px",
          padding: "15px 15px ",
          color: "#fff",
          borderRadius: "0 10px 0 10px",
          width: "fit-content",
          background: "#1a2332",
          "&:hover": {
            cursor: "pointer",
            color: "#1a2332",
            background: "#e6e6e6",
          },
        }}
      >
        Popular Posts
      </Typography>
        </div>
      </div>
    </div>
  );
};

export default Trending;
const styles = {
  cardStyle: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    minHeight: "220px",
    minWidth: "300px",
    transitionDuration: "0.3s",
    borderRadius: "4px",
    textDecoration: "none",
    // marginBottom: "10px",
  },
};
