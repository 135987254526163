import { IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  FiInstagram,
  FiGithub,
  FiTwitch,
  FiYoutube,
  FiTwitter,
} from "react-icons/fi";
import { AiOutlineReddit } from "react-icons/ai";
import { BsStackOverflow } from "react-icons/bs";

import { Link, useNavigate } from "react-router-dom";

import "./social.css";
import { UserAuth } from "../../context/AuthContext";

const SocialMediaBar = () => {
  const { loadingHome, activePage } = UserAuth();
  const navigate = useNavigate();
  if ((activePage === "home" && !loadingHome) || activePage !== "home") {
    return (
      <div className="social__media__container">
        <IconButton
          sx={{
            padding: "4px",
            "&:hover": {
              transition: "linear all 250ms",
              transform: "scale(1.1)",
            },
          }}
          onClick={() =>
            window.open("https://github.com/xsisec", "_blank").focus()
          }
        >
          <FiGithub
            className=" social_icon_style"
            color="#fff"
            fill="#fff"
            style={{
              backgroundColor: "#000",
              padding: 4,
              borderRadius: "20px",
            }}
          />
        </IconButton>
        <IconButton
          sx={{
            padding: "4px",
            "&:hover": {
              transition: "linear all 250ms",
              transform: "scale(1.1)",
            },
          }}
          onClick={() =>
            window.open("https://www.reddit.com/user/xsisec", "_blank").focus()
          }
        >
          <AiOutlineReddit
            className=" social_icon_style"
            color="#fff"
            fill="#fff"
            style={{
              backgroundColor: "#FF4500",
              padding: 4,
              borderRadius: "20px",
            }}
          />
        </IconButton>
        <IconButton
          sx={{
            padding: "4px",
            "&:hover": {
              transition: "linear all 250ms",
              transform: "scale(1.1)",
            },
          }}
          onClick={() =>
            window
              .open("https://www.twitch.tv/xsisecofficial", "_blank")
              .focus()
          }
        >
          <FiTwitch
            className=" social_icon_style"
            color="#fff"
            fill="#6441A4"
            style={{
              backgroundColor: "#6441A4",
              padding: 4,
              borderRadius: "20px",
            }}
          />
        </IconButton>
        <IconButton
          sx={{
            padding: "4px",
            "&:hover": {
              transition: "linear all 250ms",
              transform: "scale(1.1)",
            },
          }}
          onClick={() =>
            window
              .open("https://www.instagram.com/xsisecofficial/", "_blank")
              .focus()
          }
        >
          <FiInstagram
            className=" social_icon_style"
            color="#fff"
            fill="#125688"
            style={{
              backgroundColor: "#fff",
              padding: 4,
              borderRadius: "20px",
            }}
          />
        </IconButton>


        <IconButton
          sx={{
            padding: "4px",
            "&:hover": {
              transition: "linear all 250ms",
              transform: "scale(1.1)",
            },
          }}
          onClick={() =>
            window
              .open(
                "https://stackoverflow.com/users/4537270/xsisecofficial",
                "_blank"
              )
              .focus()
          }
        >
          <BsStackOverflow
            className=" social_icon_style"
            color="#fff"
            fill="#fff"
            style={{
              backgroundColor: "#F47F24",
              padding: 4,
              borderRadius: "20px",
            }}
          />
        </IconButton>
        <IconButton
          sx={{
            padding: "4px",
            "&:hover": {
              transition: "linear all 250ms",
              transform: "scale(1.1)",
            },
          }}
          onClick={() =>
            window
              .open(
                "https://www.youtube.com/channel/UCdUJ92IRutGcp-7r5Etcg0w",
                "_blank"
              )
              .focus()
          }
        >
          <FiYoutube
            className=" social_icon_style"
            color="#fff"
            fill="#FF0000"
            style={{
              backgroundColor: "#fff",
              padding: 4,
              borderRadius: "20px",
            }}
          />
        </IconButton>
      </div>
    );
  } else {
    return null;
  }
};
export default SocialMediaBar;
