import React from "react";

import { ReactComponent as Hardware } from "../../Assets/hardware.svg";
import { ReactComponent as Software } from "../../Assets/software.svg";
import { ReactComponent as Hacker } from "../../Assets/hacker.svg";

import { BiNetworkChart, BiSlider } from "react-icons/bi";
import "./HomeCard.scss";
import { Box } from "@mui/material";
function AboutCard() {
  return (
<Box className="quote-card-view">
  <Box>
    <p style={{ textAlign: "left" }}>
      Welcome! At 34, I've transitioned from a seasoned Software Tester, a role I embraced since 2014, to pursuing my passion within the realm of Cybersecurity Engineering, with a focus on Offensive Penetration Testing. This journey marks the realization of a long-held dream, merging my expertise and enthusiasm for uncovering vulnerabilities in the digital world.
    </p>
    <p>
      My technical acumen spans a broad spectrum, from networking and configurations to server management, along with a robust understanding of both software and hardware systems. 
    </p>
    <p>
      Outside the professional sphere, my curiosity and drive for challenge have led me to the fascinating world of Capture the Flag (CTF) competitions <a href="https://ctftime.org/ctf-wtf/">(CTF)</a>. These contests not only sharpen my skills but also immerse me in various aspects of cybersecurity, from steganography and exploiting vulnerabilities to reverse engineering. 
    </p>
    <p>
      CTFs are not just tests of technical skill but are puzzles that encourage persistence, learning, and collaboration. Whether it’s developing exploits or crafting websites, each challenge is an opportunity to grow and innovate.
    </p>
    <p>
      So, dive in, explore, and let’s embark on this cybersecurity adventure together. Embrace the challenges, leverage the tools at your disposal, and never hesitate to seek out new learning avenues or insights from the community.
    </p>
  </Box>
</Box>

  );
}

export default AboutCard;
