import {
  arrayRemove,
  arrayUnion, doc, onSnapshot, serverTimestamp, updateDoc
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { UserAuth } from "../../../../context/AuthContext";
import { db } from "../../../../firebase";
import { useTheme } from "@mui/material";

import moment from "moment";
import { Container } from "react-bootstrap";
import anonymous_avatar from "../../../../Assets/anonymous_avatar.png";
import "./Comment.scss";
const Comment = ({ id_comment }) => {
  const theme = useTheme();
  const { user } = UserAuth();
  const { id } = useParams();

  const [author, SetAuthor] = useState("");
  const [comments, setComments] = useState("");
  const [comment, setComment] = useState({
    comment: "",
    author: "",
    createdAt: serverTimestamp(),
    commentId: "",
  });

  useEffect(() => {
    const docRef = doc(db, "projects", id);
    onSnapshot(docRef, (snapshot) => {
      setComments(snapshot.data().comments);
      SetAuthor(snapshot.data().author);
    });
  }, []);

  const handleDeleteComment = async (commentId, author, comment, createdAt) => {
    const docRef = doc(db, "projects", id);
    // console.log( commentId, comment, docRef);

    try {
      await updateDoc(docRef, {
        comments: arrayRemove({ commentId, author, comment, createdAt }),
      });
      toast.success("Comment has been created", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  const commentRef = doc(db, "projects", id);

  const handleChangeComment = () => {
    updateDoc(commentRef, {
      comments: arrayUnion({
        comment: comment,
        author: author,
        createdAt: new Date(),
        commentId: uuidv4(),
      }),
    });
  };

  const CARD_PROPERTY = {
    borderRadius: 1,
    boxShadow: 0,
    background: "0000",
  };

  return (
    <Container fluid className="comment-section" id="home">
      <Container className="comment-content">
      <h1 style={{color: theme.palette.color.lightBlueForBlogDetails}}>Comments</h1>
        {comments &&
          comments.map(({ commentId, author, comment, createdAt }) => (
            <div key={commentId}>
              <div className="comments-container">
                <ul id="comments-list" className="comments-list">
                  <div className="comment-avatar">
                    <img src={anonymous_avatar} alt="" />
                  </div>
                  <div className="comment-box">
                    <div className="comment-head">
                      <h6 className="comment-name by-author">
                        <a href="http://creaticode.com/blog">{author}</a>
                      </h6>
                      <span>
                        {moment(createdAt * 1000).format(
                          "MM ddd, YYYY HH:mm:ss a"
                        )}
                      </span>
                      <i className="fa fa-heart"></i>
                      {user ? (
                        <>
                          {
                            <i
                              className="fa fa-times"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleDeleteComment(
                                  commentId,
                                  author,
                                  comment,
                                  createdAt
                                )
                              }
                            ></i>
                          }
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="comment-content">{comment}</div>
                  </div>
                </ul>
              </div>
            </div>
          ))}
        {
          <div>
            <p />
            <p />
            <p />
            <input
              name="description"
              className="form-control"
              onChange={(e) => {
                SetAuthor(e.target.value);
              }}
              placeholder="Author..."
            />
            <p />
            <textarea
              name="description"
              className="form-control"
              maxLength={300}
              onChange={(e) => {
                setComment(e.target.value);
              }}
              placeholder="Add a comment"
            />
            <br />
            <button
              className="form-control btn btn-outline-success"
              onClick={() => {
                handleChangeComment();
              }}
            >
              Publish
            </button>
          </div>
        }
      </Container>
    </Container>
  );
};

export default Comment;
