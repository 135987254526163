import "./Navbar.scss";

import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link, NavLink, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { collection, onSnapshot, query } from "firebase/firestore";

import { ReactComponent as BlogpostIcon } from "../../Assets/icons/Blogpost.svg";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import DrawerComponent from "./Drawer";
import { ReactComponent as HomeIcon } from "../../Assets/icons/Home.svg";
// import IconButton from "@mui/material/IconButton";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import NavDropdown from "react-bootstrap/NavDropdown";
import { ReactComponent as ProjectIcon } from "../../Assets/icons/Projects.svg";
import { ReactComponent as SkillsIcon } from "../../Assets/icons/Skills.svg";
import { UserAuth } from "../../context/AuthContext";
import avatar_admin from "../../Assets/avatar_admin.png";
import avatar_non_admin from "../../Assets/avatar_non_admin.png";
import { db } from "../../firebase";
import hardwareImg from "../../Assets/categories/hardware.png";
//search dropdown
import { makeStyles } from "@mui/styles";
import securityImg from "../../Assets/categories/security.jpg";
import terminalImg from "../../Assets/categories/terminal.png";
import textBg from "../../Assets/back.png";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import videoImg from "../../Assets/categories/videoicon.png";

const useStyles = makeStyles((theme) => ({
  cardStyle: {
    backgroundColor: "#6fb53a",
  },
  navlinks: {
    // marginLeft: theme.spacing(5),
    display: "flex",
    flexGrow: "1",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  logo: {
    cursor: "pointer",
    fontSize: "15px",
    color: "transparent",
    //" -webkit-text-stroke": "1px #fff",
    background: `url(${textBg})`,
    "-webkit-background-clip": "text",
    backgroundPosition: "0 0",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.color.lightBlue,
    // backgroundColor: theme.palette.secondary.main,
    // color: "#00bf9a",
    fontSize: "15px",
    margin: "0px 1rem",

    // marginLeft: theme.spacing(20),
    "&:hover": {
      color: "#bf7c00",
      //   borderBottom: "1px solid white",
    },
  },
  activeLink: {
    textDecoration: "none",
    color: theme.palette.color.lightGreen,
    fontSize: "15px",
    margin: "0px 1rem",
    borderBottom: "3px solid white",
    borderBottomColor: "#70ad0c",
    content: "",
  },
  select: {
    border: "0px",
    borderStyle: "0",
    "&:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    },
    icon: {
      fill: "#fff",
    },
    menuButton: {
      color: theme.palette.primary.contrastText,
    },
  },
}));

function Navbar(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { user, logout } = UserAuth();

  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [projects, setProjects] = useState([]);
  const [portfolio, setPortfolio] = useState([]);
  const [randoms, setRandoms] = useState([]);
  const [value, setValue] = useState("");
  const searchRef = useRef(null);
  const clickedOutside = useOutsideClick(searchRef);
  const [activeSearch, setActiveSearch] = useState(false);
  var icon_width = 32;
  var icon_height = 32;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //dropdown

  //end

  const onChange = (event) => {
    //console.log("Typed", event.target.value);
    setActiveSearch(true);
    setValue(event.target.value);
  };

  const onSearch = (searchTerm, searchId, type) => {
    setValue("");
    // our api to fetch the search result
    console.log("search ", searchTerm, "and id", searchId);

    switch (type) {
      case "blogs": {
        navigate(`/blog/${searchId}`, { state: { id: searchId } });
        return;
      }
      case "portfolio": {
        navigate(`/portfolio/${searchId}`, {
          state: { id: searchId },
        });
        return;
      }
      case "projects": {
        navigate(`/projects/${searchId}`, {
          state: { id: searchId },
        });
        return;
      }
      case "rand0ms": {
        navigate(`/randoms/${searchId}`, {
          state: { id: searchId },
        });
        return;
      }
      default:
    }
  };
  useEffect(() => {
    // if the the click was outside of the button
    // do whatever you want
    if (clickedOutside) {
      console.log("you clicked outside of the search");
      setActiveSearch(false);
    }
  }, [clickedOutside]);

  useEffect(() => {
    const blogsRef = collection(db, "blogs");
    const blogsQuery = query(blogsRef);
    const unsubBlogs = onSnapshot(
      blogsQuery,
      (snapshot) => {
        //console.log("Blog snapshot is", snapshot.docs.length);
        let list = [];
        let tags = [];
        snapshot.docs.forEach((doc) => {
          // console.log("Blog is", doc.data());
          list.push({ id: doc.id, type: "blogs", ...doc.data(), key: doc.id });
        });
        setBlogs(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsubBlogs();
    };
  }, []);

  useEffect(() => {
    const portfolioRef = collection(db, "portfolio");
    const portfolioQuery = query(portfolioRef);
    const unsub = onSnapshot(
      portfolioQuery,
      (snapshot) => {
        //console.log("Blog snapshot is", snapshot.docs.length);
        let list = [];
        let tags = [];
        snapshot.docs.forEach((doc) => {
          //console.log("Portfolio is", doc.data());
          list.push({
            id: doc.id,
            type: "portfolio",
            ...doc.data(),
            key: doc.id,
          });
        });
        setPortfolio(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    const projectsRef = collection(db, "projects");
    const projectsQuery = query(projectsRef);
    const unsubProjects = onSnapshot(
      projectsQuery,
      (snapshot) => {
        //console.log("Blog snapshot is", snapshot.docs.length);
        let list = [];
        let tags = [];
        snapshot.docs.forEach((doc) => {
          // console.log("Randoms is", doc.data());
          list.push({
            props: doc.id,
            id: doc.id,
            type: "projects",
            ...doc.data(),
          });
        });

        setProjects(list);
      },
      (error) => {
        console.log(error);
      }
    );
    return () => {
      unsubProjects();
    };
  }, []);

  useEffect(() => {
    const randomsRef = collection(db, "rand0ms");
    const randomsQuery = query(randomsRef);
    const unsubRandoms = onSnapshot(
      randomsQuery,
      (snapshot) => {
        //console.log("Blog snapshot is", snapshot.docs.length);
        let list = [];
        let tags = [];
        snapshot.docs.forEach((doc) => {
          // console.log("Randoms is", doc.data());
          list.push({
            id: doc.id,
            type: "rand0ms",
            ...doc.data(),
            key: doc.id,
          });
        });

        setRandoms(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsubRandoms();
    };
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      console.log("You are logged out");
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        zIndex: 100,
        backgroundColor: theme.palette.color.NavbarBackground,
        width: "100%",
        fontFamily: "Nunito",
        fontSize: "15px",
      }}
    >
      <CssBaseline />
      <Container maxWidth="lg">
        <Toolbar
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: theme.palette.color.white,
          }}
        >
          <Link to='/' style={{
            textDecoration: 'inherit',
            color:'white'
          }}>XsiSec.com</Link>
          {isMobile ? (
            <DrawerComponent />
          ) : (
            <div className={classes.navlinks}>
              {/* <BackgroundSwitch/> */}
              <IconButton
                onClick={props.toggleThemeMode}
                style={{
                  cursor: "pointer",
                  position: "relative",
                  top: "0",
                  left: "0",
                  zIndex: "1000000",
                }}
              >
                {props.themeMode === "light" ? (
                  <Brightness4Icon />
                ) : (
                  <Brightness7Icon color="primary" />
                )}
              </IconButton>

              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? classes.activeLink : classes.link
                }
              >
                <HomeIcon
                  height={icon_height}
                  width={icon_width}
                  fill={theme.palette.color.lightGreen}
                  key="HomeIcon"
                />
                {""} Home
              </NavLink>
              <NavLink
                to="/skills"
                className={({ isActive }) =>
                  isActive ? classes.activeLink : classes.link
                }
              >
                <SkillsIcon
                  height={icon_height}
                  width={icon_width}
                  fill={theme.palette.color.lightGreen}
                />
                {""} Skills
              </NavLink>
              <NavLink
                to="/bloglist"
                className={({ isActive }) =>
                  isActive ? classes.activeLink : classes.link
                }
              >
                <BlogpostIcon
                  height={icon_height}
                  width={icon_width}
                  fill={theme.palette.color.lightGreen}
                />
                {""}
                Blog
              </NavLink>
              <NavLink
                to="/projectlist"
                className={({ isActive }) =>
                  isActive ? classes.activeLink : classes.link
                }
              >
                <ProjectIcon
                  height={icon_height}
                  width={icon_width}
                  fill={theme.palette.color.lightGreen}
                />
                {""}
                Projects
              </NavLink>

              <NavLink
                to="/portfoliolist"
                className={({ isActive }) =>
                  isActive ? classes.activeLink : classes.link
                }
              >
                <ProjectIcon
                  height={icon_height}
                  width={icon_width}
                  fill={theme.palette.color.lightGreen}
                />
                {""}
                Portfolio
              </NavLink>

   

              <div ref={searchRef} className="search-container">
                <input
                  type="text"
                  placeholder="Search for posts... "
                  value={value}
                  onChange={onChange}
                  style={{
                    backgroundColor: theme.palette.color.deepLightGrey,
                    width: "100%",
                    borderRadius: "4px",
                    border: "3px solid transparent",
                    color: "#fff",
                  }}
                />
                {activeSearch ? (
                  <div className="dropdownSearch">
                    {[]
                      .concat(blogs)
                      .concat(randoms)
                      .concat(portfolio)
                      .concat(projects)
                      .filter((item, i) => {
                        const searchTerm = value.toLowerCase();
                        const fullName = item.title.toLowerCase();

                        return (
                          searchTerm &&
                          fullName.includes(searchTerm) &&
                          fullName !== searchTerm
                        );
                      })
                      .slice(0, 10)
                      .map((item, index) => (
                        <NavDropdown.Item
                          key={index}
                          onClick={() =>
                            onSearch(item.title, item.id, item.type)
                          }
                        >
                          <Box
                            sx={{
                              background:
                                theme.palette.color.DropdownMenuBackground,
                              display: "flex",
                              width: "100%",
                              paddingTop: "5px",
                              paddingLeft: "5px",
                              paddingBottom: "10px",
                              borderBottom: "1px solid #141d2b",
                              "&:hover": {
                                cursor: "pointer",
                                backgroundColor:
                                  theme.palette.color
                                    .DropdownMenuBackgroundHover,
                              },
                            }}
                          >
                            {item?.type === "blogs" ? (
                              <img
                                style={{ width: "40px", height: "40px" }}
                                src={securityImg}
                                alt={item.title}
                                key={item.id}
                              />
                            ) : (
                              <></>
                            )}
                            {item?.type === "projects" ? (
                              <img
                                style={{ width: "40px", height: "40px" }}
                                src={hardwareImg}
                                alt={item.title}
                                key={item.id}
                              />
                            ) : (
                              <></>
                            )}
                            {item?.type === "portfolio" ? (
                              <img
                                style={{ width: "40px", height: "40px" }}
                                src={videoImg}
                                alt={item.title}
                                key={item.id}
                              />
                            ) : (
                              <></>
                            )}
                            {item?.type === "rand0ms" ? (
                              <img
                                style={{ width: "40px", height: "40px" }}
                                src={terminalImg}
                                alt={item.title}
                                key={item.id}
                              />
                            ) : (
                              <></>
                            )}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                paddingLeft: "10px",
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: "bold", color: "#fff" }}
                              >
                                {item.title.slice(0, 35)}...
                              </Typography>

                              <Typography
                                style={{
                                  color: "rgb(99, 192, 245)",
                                  textShadow: "rgb(104 182 255) 0px 0px 5px",
                                }}
                              >
                                {item.category
                                  ? `${item.category}`
                                  : `${item.type}`}
                              </Typography>

                              {item.type === "blogs" ? (
                                <>
                                  {item.tags.slice(0, 3).map((tag, index) => {
                                    return (
                                      <Typography
                                        key={index}
                                        variant="body2"
                                        style={{
                                          backgroundColor: "rgba(159,239,0,.5)",
                                          color: "#fff",
                                          fontSize: "12px",
                                          width: "fit-content",
                                          padding: "2px 5px",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        {tag}
                                      </Typography>
                                    );
                                  })}
                                </>
                              ) : null}
                            </div>
                          </Box>
                        </NavDropdown.Item>
                      ))}
                  </div>
                ) : null}
              </div>

              {user && user.roles.admin === true ? (
                <div>
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    className={classes.menuButton}
                  >
                    <img src={avatar_admin} width="20px" height="20px" alt="" />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleLogout}>Sign-out</MenuItem>
                    <MenuItem component={Link} to="/account">
                      Account
                    </MenuItem>
                    <MenuItem component={Link} to="/add-blogpost">
                      Create Blogpost
                    </MenuItem>
                    <MenuItem component={Link} to="/add-projectpost">
                      Create ProjectPost
                    </MenuItem>
                    <MenuItem component={Link} to="/add-portfoliopost">
                      Create PortfolioPost
                    </MenuItem>
                    <MenuItem component={Link} to="/add-changehistory">
                      Create ChangeHistory
                    </MenuItem>
                  </Menu>
                </div>
              ) : (
                <div>
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    className={classes.menuButton}
                  >
                    <img
                      src={avatar_non_admin}
                      width="20px"
                      height="20px"
                      alt=""
                    />{" "}
                  </IconButton>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem component={Link} to="/sign-in">
                      Sign-in
                    </MenuItem>{" "}
                  </Menu>
                </div>
              )}
              {user && user.roles.admin !== true ? <></> : null}
            </div>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
