import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useTheme
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import "./ProjMostPopular.scss";
const ProjMostPopular = ({ blogs }) => {
  const clonedCopyBlogs = JSON.parse(JSON.stringify(blogs));
  const most_popular = clonedCopyBlogs?.sort((a, b) => {
    const a_popularity =
      a?.likedBy.length +
      a?.likedByIp.length +
      a?.clappedBy.length +
      a?.clappedByIp.length;
    const b_popularity =
      b?.likedBy.length +
      b?.likedByIp.length +
      b?.clappedBy.length +
      b?.clappedByIp.length;
    return b_popularity - a_popularity;
  });

  const theme = useTheme();

  return (
    <div>
      <br/>
      <br/>
      {/* <Typography
        sx={{
          margin: "1em 0 0.5em 0",
          fontWeight: "600",
          fontFamily: "Geo",
          position: "relative",
          fontSize: "20px",
          lineHeight: "20px",
          padding: "10px 10px ",
          color: theme.palette.color.white,
          borderRadius: "0 10px 0 10px",
          width: "fit-content",
          backgroundColor: theme.palette.color.orange,
          "&:hover": {
            cursor: "pointer",
            color: "#1a2332",
            background: "#e6e6e6",
          },
        }}
      >
        Most Read
      </Typography> */}
      <Grid container spacing={2} justifyContent="center">
        {most_popular?.slice(0, 3).map((item) => (
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <Card
              component={Link}
              to={`/projects/${item.id}`}
              state={{ id: item.id }}
              style={styles.cardStyle}
              key={item?.id}
            >
              <CardMedia
                component="div"
                height="50"
                sx={{ backgroundColor: "#111927" }}
                alt="Background"
              />
              <CardContent
                className="card__content"
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  gutterBottom
                  variant="h"
                  component="div"
                  sx={{
                    fontWeight: "bold",
                    fontFamily:"Geo",
                    fontSize: "1.2rem",
                    color: theme.palette.color.limeGreen,
                    "&:hover": {
                      color: "#e68ce3",
                      cursor: "pointer",
                    },
                  }}
                >
                  {item?.title}
                </Typography>
              </CardContent>
              <CardActions
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  position: "relative",
                  zIndex: 5,
                  height: "40px",
                  backgroundColor: theme.palette.color.lightGrey,
                }}
              >
                <Box>
                  <Button
                    size="small"
                    component={Link}
                    to={`/projects/${item.id}`}
                    state={{ id: item.id }}
                    key={item?.id}
                    sx={{
                      color: theme.palette.color.red,
                      textDecoration: "none",
                      "&:hover": {
                        color: "#ce0ff5",
                        backgroundColor: "#000",
                      },
                    }}
                  >
                    Read More
                  </Button>
                </Box>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <br/>
    </div>
    
  );
};

export default ProjMostPopular;
const styles = {
  cardStyle: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    minHeight: "220px",
    minWidth: "300px",
    transitionDuration: "0.3s",
    borderRadius: "4px",
    textDecoration: "none",
  },
};
