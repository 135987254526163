import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { db } from "../../../../firebase";
import Tags from "../../Tags/Tags";
import TagContentPosts from "./TagContentPosts/TagContentPosts";


import {useTheme, Box, Container } from "@mui/material";
import Comments from "../Portfolio-comments/Comment";
import "./PortfolioPostDetail.scss";
const PortfolioPostDetail = ({ data }) => {
  const { state } = useLocation();
  const { id } = state;
  const theme = useTheme();


  // console.log("State is", state);

  const [blog, setBlog] = useState(null);


  const getBlogDetail = async () => {
    const docRef = doc(db, "portfolio", id);
    const blogDetail = await getDoc(docRef);
    console.log("Portfolio data is", blogDetail.data());
    setBlog(blogDetail.data());
  };

  useEffect(() => {
    getBlogDetail();
  }, []);


  return (
    <Container sx={{ width: "100%" }}>
      {/* <Particle /> */}
      <Container
        maxWidth="lg"
        sx={{
          width: "100%",
          marginTop: "80px",
          position: "relative",
          zIndex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgb(9,46,32,0.3)",
          borderRadius: 3,
        }}
      >
        <Box
          sx={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="blogpost-name">
            <p className="blogpost-detail-title" style={{ color: theme.palette.color.PortfolioDetailTitleColour }}>
              {blog?.title}
            </p>
          </h1>
          <p className="blogpost-detail-author">
            Author:
            {blog?.author}
          </p>
        </Box>
        <div
          style={{ width: "100%", height: "1px", backgroundColor: "gold" }}
        />

        <div
          style={{
            color: "#fff",
            minHeight: "300px",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          dangerouslySetInnerHTML={{ __html: blog?.content }}
        />
        <div
          style={{ width: "100%", height: "1px", backgroundColor: "gold" }}
        />
 

        <TagContentPosts direction="row" tags={blog?.OperativSystemTags} />

        <TagContentPosts direction="row" tags={blog?.WindowManagerTags} />


        <TagContentPosts direction="row" tags={blog?.StyleTags} />
        
        <Tags direction="row" tags={blog?.tags} />

        <Comments />
        <hr/>
      </Container>
    </Container>
  );
};

export default PortfolioPostDetail;
