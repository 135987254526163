import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Link, NavLink, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { collection, onSnapshot, query } from "firebase/firestore";

import Menu from "@mui/icons-material/Menu";
import { NavDropdown } from "react-bootstrap";
import { UserAuth } from "../../context/AuthContext";
import { db } from "../../firebase";
import defaultImg from "../../Assets/blog/bg_1.jpg";
import hardwareImg from "../../Assets/categories/hardware.png";
import { makeStyles } from "@mui/styles";
import { useOutsideClick } from "../../hooks/useOutsideClick";

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: "none",
    color: "#a4b1cd",
    fontSize: "15px",
    margin: "0px 1rem",
    // marginLeft: theme.spacing(20),
    "&:hover": {
      color: "#9fef00",
      //   borderBottom: "1px solid white",
    },
  },
  activeLink: {
    textDecoration: "none",
    color: "#9fef00",
    fontSize: "18px",
    margin: "px 1rem",
    borderBottom: "1px solid white",
    // marginLeft: theme.spacing(20),
  },
  icon: {
    color: "white",
  },
}));

function DrawerComponent() {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);

  const { user, logout } = UserAuth();

  const navigate = useNavigate();
  const [navColour, updateNavbar] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [projects, setProjects] = useState([]);
  const [portfolio, setPortfolio] = useState([]);
  const [randoms, setRandoms] = useState([]);
  const [value, setValue] = useState("");
  const searchRef = useRef(null);
  const clickedOutside = useOutsideClick(searchRef);
  const [activeSearch, setActiveSearch] = useState(false);

  const onChange = (event) => {
    console.log("Typed", event.target.value);
    setActiveSearch(true);
    setValue(event.target.value);
  };

  const onSearch = (searchTerm, searchId, type) => {
    setValue("");
    setOpenDrawer(false);
    // our api to fetch the search result
    console.log("search ", searchTerm, "and id", searchId);
    switch (type) {
      case "blogs": {
        navigate(`/blog/${searchId}`, { state: { id: searchId } });
        return;
      }

      case "portfolio": {
        navigate(`/portfolio/${searchId}`, {
          state: { id: searchId },
        });
        return;
      }
      case "projects": {
        navigate(`/projects/${searchId}`, {
          state: { id: searchId },
        });
        return;
      }
      case "rand0ms": {
        navigate(`/randoms/${searchId}`, {
          state: { id: searchId },
        });
        return;
      }
      default:
    }
  };
  useEffect(() => {
    // if the the click was outside of the button
    // do whatever you want
    if (clickedOutside) {
      console.log("you clicked outside of the search");
      setActiveSearch(false);
    }
  }, [clickedOutside]);

  useEffect(() => {
    const blogsRef = collection(db, "blogs");
    const blogsQuery = query(blogsRef);
    const unsubBlogs = onSnapshot(
      blogsQuery,
      (snapshot) => {
        //console.log("Blog snapshot is", snapshot.docs.length);
        let list = [];
        let tags = [];
        snapshot.docs.forEach((doc) => {
          // console.log("Blog is", doc.data());
          list.push({ id: doc.id, type: "blogs", ...doc.data() });
        });
        setBlogs(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsubBlogs();
    };
  }, []);

  useEffect(() => {
    const portfolioRef = collection(db, "portfolio");
    const portfolioQuery = query(portfolioRef);
    const unsub = onSnapshot(
      portfolioQuery,
      (snapshot) => {
        //console.log("Blog snapshot is", snapshot.docs.length);
        let list = [];
        let tags = [];
        snapshot.docs.forEach((doc) => {
          //console.log("Portfolio is", doc.data());
          list.push({ id: doc.id, type: "portfolio", ...doc.data() });
        });
        setPortfolio(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    const projectsRef = collection(db, "projects");
    const projectsQuery = query(projectsRef);
    const unsubProjects = onSnapshot(
      projectsQuery,
      (snapshot) => {
        //console.log("Blog snapshot is", snapshot.docs.length);
        let list = [];
        let tags = [];
        snapshot.docs.forEach((doc) => {
          // console.log("Randoms is", doc.data());
          list.push({ id: doc.id, type: "projects", ...doc.data() });
        });

        setProjects(list);
      },
      (error) => {
        console.log(error);
      }
    );
    return () => {
      unsubProjects();
    };
  }, []);

  useEffect(() => {
    const randomsRef = collection(db, "rand0ms");
    const randomsQuery = query(randomsRef);
    const unsubRandoms = onSnapshot(
      randomsQuery,
      (snapshot) => {
        //console.log("Blog snapshot is", snapshot.docs.length);
        let list = [];
        let tags = [];
        snapshot.docs.forEach((doc) => {
          // console.log("Randoms is", doc.data());
          list.push({ id: doc.id, type: "rand0ms", ...doc.data() });
        });

        setRandoms(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsubRandoms();
    };
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      console.log("You are logged out");
    } catch (e) {
      console.log(e.message);
    }
  };

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);
  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
          sx: {
            width: "fit-content",
            backgroundColor: "#1a2332",
          },
        }}
      >
        <List>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? classes.activeLink : classes.link
                }
              >
                Home
              </NavLink>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <NavLink
                to="/skills"
                className={({ isActive }) =>
                  isActive ? classes.activeLink : classes.link
                }
              >
                Skills
              </NavLink>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <NavLink
                to="/bloglist"
                className={({ isActive }) =>
                  isActive ? classes.activeLink : classes.link
                }
              >
                Blog
              </NavLink>
            </ListItemText>
          </ListItem>

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <NavLink
                to="/projectlist"
                className={({ isActive }) =>
                  isActive ? classes.activeLink : classes.link
                }
              >
                Projects
              </NavLink>
            </ListItemText>
          </ListItem>

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <NavLink
                to="/portfoliolist"
                className={({ isActive }) =>
                  isActive ? classes.activeLink : classes.link
                }
              >
                Portfolio
              </NavLink>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemText className={classes.link}>Account</ListItemText>
            {!user ? (
              <Select
                classes={{ icon: { color: "#fff", fill: "#fff" } }}
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                    borderRadius: "0",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#fff",
                  },
                  color: "#fff",
                  fill: "#fff",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={age}
                label="Account"
                // onChange={handleChange}
              >
                <MenuItem component={Link} to="/sign-in">
                  Sign-in
                </MenuItem>
                <MenuItem component={Link} to="/sign-up">
                  Sign Up
                </MenuItem>
              </Select>
            ) : null}
            {user && user.roles.admin === true ? (
              <Select
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  "& .MuiSvgIcon-root": {
                    color: "#fff",
                  },
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={age}
                label="Account"
                // onChange={handleChange}
              >
                <MenuItem component={Link} to="/account">
                  Account
                </MenuItem>

                <MenuItem component={Link} to="/add-blogpost">
                  Create Blogpost
                </MenuItem>
                <MenuItem component={Link} to="/add-projectpost">
                  Create ProjectPost
                </MenuItem>
                <MenuItem component={Link} to="/add-portfoliopost">
                  Create PortfolioPost
                </MenuItem>
                <MenuItem component={Link} to="/add-changehistory">
                  Create ChangeHistory
                </MenuItem>
                <MenuItem onClick={handleLogout}>Sign-out</MenuItem>
              </Select>
            ) : null}
            {user && user.roles.admin !== true ? <></> : null}
          </ListItem>
          <ListItem>
            <div ref={searchRef} className="search-container-mobile">
              <div className="search-inner">
                <input
                  type="text"
                  placeholder="Search ..."
                  value={value}
                  onChange={onChange}
                  style={{
                    backgroundColor: "#141d2b",

                    borderRadius: "4px",
                    border: "3px solid transparent",
                    color: "#fff",
                  }}
                />
              </div>
              {activeSearch ? (
                <div className="dropdownSearch-mobile">
                  {[]
                    .concat(blogs)
                    .concat(randoms)
                    .concat(portfolio)
                    .concat(projects)
                    .filter((item) => {
                      const searchTerm = value.toLowerCase();
                      const fullName = item.title.toLowerCase();

                      return (
                        searchTerm &&
                        fullName.includes(searchTerm) &&
                        fullName !== searchTerm
                      );
                    })
                    .slice(0, 10)
                    .map((item) => (
                      <NavDropdown.Item
                        // style={{ fontSize: "15px", color: "#1e1e1e" }}
                        onClick={() => onSearch(item.title, item.id, item.type)}
                        // className="dropdown-row"
                        key={item.title}
                      >
                        {/* {item.type === "blogs" ? (
                            <FaBloggerB />
                          ) : item.type === "portfolio" ? (
                            <FaMicroblog />
                          ) : item.type === "rand0ms" ? (
                            <FaRandom />
                          ) : null} */}
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            paddingTop: "5px",
                            paddingLeft: "5px",
                            paddingBottom: "10px",
                            borderBottom: "1px solid #141d2b",
                            "&:hover": {
                              cursor: "pointer",
                              backgroundColor: "#141d2b",
                            },
                            overflowWrap: "break-word",
                          }}
                        >
                          {item?.image ? (
                            <img
                              style={{ width: "50px", height: "50px" }}
                              src={item?.image}
                              alt={item.title}
                            />
                          ) : (
                            <img
                              style={{ width: "50px", height: "50px" }}
                              src={defaultImg}
                              alt={item.title}
                            />
                          )}
                          <div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                paddingLeft: "10px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  color: "#fff",
                                  wordWrap: "break-word",
                                }}
                              >
                                {item.title.slice(0, 35)}...
                              </Typography>
                              <Typography
                                style={{
                                  color: "rgb(99, 192, 245)",
                                  textShadow: "rgb(104 182 255) 0px 0px 5px",
                                }}
                              >
                                {item.category
                                  ? `${item.category}`
                                  : `${item.type}`}
                              </Typography>
                              {item.type === "blogs" ? (
                                <div style={{ display: "flex", width: "100%" }}>
                                  {item.tags.slice(0, 3).map((tag, idx) => {
                                    return (
                                      <Typography
                                        variant="body2"
                                        style={{
                                          backgroundColor: "rgba(159,239,0,.5)",
                                          color: "#fff",
                                          fontSize: "12px",
                                          width: "fit-content",
                                          padding: "2px 5px",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        {tag}
                                      </Typography>
                                    );
                                  })}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </Box>
                      </NavDropdown.Item>
                    ))}
                </div>
              ) : null}
            </div>
          </ListItem>
        </List>
      </Drawer>
      <IconButton
        onClick={() => setOpenDrawer(!openDrawer)}
        className={classes.icon}
      >
        <Menu />
      </IconButton>
    </>
  );
}
export default DrawerComponent;
