import { Box, Grid } from "@mui/material";
import React from "react";

import {
  SiCodepen, SiCodesandbox, SiStackbit, SiStackoverflow
} from "react-icons/si";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  inspirationStack: {
    color: theme.palette.color.white,
  },
}));


function InspirationStack() {
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.inspirationStack}>
      <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
        <Box className="tech-icons">
          <SiCodepen />
          <p>Codepen</p>
        </Box>
      </Grid>

      <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
        <Box className="tech-icons">
          <SiStackoverflow fill="yellow" />
          <p>Stackoverflow</p>
        </Box>
      </Grid>

      <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
        <Box className="tech-icons">
          <SiCodesandbox fill="magenta" />
          <p>Codesandbox</p>
        </Box>
      </Grid>

      <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
        <Box className="tech-icons">
          <SiStackbit fill="cyan" />
          <p>Stackblitz</p>
        </Box>
      </Grid>
    </Grid>
  );
}

export default InspirationStack;
