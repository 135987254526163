import React from "react";
import { Box, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Helmet from "react-helmet";
import ReactMarkdown from "react-markdown";
import "./PrivacyPolicy.scss";

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.color.white,
  },
  cardStyle: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    maxHeight: "400px",
    minWidth: "300px",
    transitionDuration: "0.3s",
    borderRadius: "4px",
    transition: "all 250ms linear",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
    marginBottom: "10px",
  },
  imgStyle: {
    transition: "all 250ms ease-in-out",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
    },
  },
}));

function PrivacyPolicy() {
  const classes = useStyles();

  const privacyPolicyMarkdown = `
# Privacy Policy

This Privacy Policy describes how your personal information is collected, used, and shared when you visit [www.xsisec.com](https://www.xsisec.com)

## Personal Information We Collect

When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information”.

We collect Device Information using the following technologies:
- “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit [All About Cookies](http://www.allaboutcookies.org).
- “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.
- “Web beacons”, “tags”, and “pixels” are electronic files used to record information about how you browse the Site.

## How Do We Use Your Personal Information?

We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).

## Sharing Your Personal Information

We do not sell, trade, or otherwise transfer your personal information to third parties.

## Data Retention

When you leave a comment on the Site, we collect the data shown in the comments form, and also your IP address and browser user agent string to help spam detection.

## Changes

We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.

## Contact Us

For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at [info@xsisec.com](mailto:info@xsisec.com).
`;

  return (
    <>
      <Helmet>
        <title>XsiSec.com | Privacy Policy</title>
        <meta
          name="description"
          content="Contact us if you have any question or requests"
        />
      </Helmet>
      <section>
        <Container className={`skills-section ${classes.text}`}>
          <Box className={`skills-section ${classes.text}`}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ReactMarkdown>{privacyPolicyMarkdown}</ReactMarkdown>
            </Box>
          </Box>
        </Container>
      </section>
    </>
  );
}

export default PrivacyPolicy;
