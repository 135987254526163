// import Particle from "../Particles/Particle.jsx";
import { Box, Container, Grid, useTheme } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import GithubCard from "./Github";

import GitHubData from "./GitHubData";
import Pagination from "./Pagination";
import Filter from "./Filter";
import GithubIcon from "../../../Assets/github.png";



import "./Skills.scss";
// import Spinner from "../../../utility/Spinner/Spinner";
import Helmet from "react-helmet";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.color.white,
  },
}));

function Skills() {
  const classes = useStyles();
  const LEVEL_FACTOR = 0.025;
  const [codeStatsData, setcodeStatsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  const [posts, setPosts] = useState([]);

  const fetchGitData = async () => {
    setLoading(true);
    const res = await axios.get("https://api.github.com/users/XsiSec/repos");
    setPosts(res.data);
    console.log(posts);
    setLoading(false);
  };

  const [todos, setTodos] = useState([]);
  const [todosLoaded, setTodosLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [todosPerPage, setTodosPerPage] = useState(5);

  useEffect(() => {
    (async () => {
      const res = await fetch("https://api.github.com/users/XsiSec/repos?q=blog&sort=updated&order=desc");
      const todos = await res.json();

      setTodos(todos);
      setTodosLoaded(true);
    })();
  }, []);

  const indexOfLastTodo = currentPage * todosPerPage;
  const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
  const currentTodos = todos.slice(indexOfFirstTodo, indexOfLastTodo);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //console.log(codeStatsData)

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const colorClasses = [
    "color-0",
    "color-1",
    "color-2",
    "color-3",
    "color-4",
    "color-5",
    "color-6",
    "color-7",
    "color-8",
    "color-9",
    "color-10",
  ];
  const yellowColorClasses = [
    "color-yellow-0",
    "color-yellow-1",
    "color-yellow-2",
    "color-yellow-3",
    "color-yellow-4",
    "color-yellow-5",
    "color-yellow-6",
    "color-yellow-7",
    "color-yellow-8",
    "color-yellow-9",
    "color-yellow-10",
  ];

  const colorZero = ["color-11"];
  const codeDates = [];
  const codeLanguages = [];

  const days = Array(31)
    .fill(0)
    .map((_, i) => i + 1);

  for (let k in codeStatsData.dates) {
    codeDates.push(k);
  }

  for (let k in codeStatsData.languages) {
    codeLanguages.push(k);
  }

  //console.log(codeLanguages)

  const get_level = (xp) => {
    return Math.floor(Math.sqrt(xp) * LEVEL_FACTOR);
    console.log("running");
  };

  const get_current_xp = (xp) => {
    return xp;
  };
  const get_next_level_xp = (xp) => {
    return Math.ceil((xp + 1) / LEVEL_FACTOR) ** 2;
  };
  const get_remaining_xp = (xp) => {
    return get_next_level_xp(0) + xp;
  };

  const get_level_progress = (xp) => {
    const level = get_level(xp);
    const current_level_xp = get_next_level_xp(level - 1);
    const next_level_xp = get_next_level_xp(level);
    const have_xp = xp - current_level_xp;
    const needed_xp = next_level_xp - current_level_xp;
    return Math.round((have_xp / needed_xp) * 100);
  };

  useEffect(() => {
    setLoading(true);
    fetchGitData();
    setLoading(false);
    get_level();
  }, []);

  return (
    <>
      <Helmet>
        <title>XsiSec.com | Skills</title>
        <meta
          name="description"
          content="Contact us if you have any question or requests"
        />
      </Helmet>
      <section>
        <Container className={`skills-section ${classes.text}`}>
          {/* <Particle /> */}
          <Box className={`skills-section ${classes.text}`}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={6}>
                  <div className="box item2">
                    <ul className={`skills-bar-container ${classes.text}`}>
                      <h2>Programming</h2>
                      <label>Java</label>
                      <span className="percent"></span>
                      <li>
                        <span
                          className="progressbar progressJAVA"
                          id="progress-java"
                        ></span>
                      </li>
                      <label>C#</label>
                      <span className="percent" id="angular-pourcent"></span>
                      <li>
                        <span
                          className="progressbar progressCSHARP"
                          id="progress-csharp"
                        ></span>
                      </li>
                      <label>.net</label>
                      <span className="percent" id="angular-pourcent"></span>
                      <li>
                        <span
                          className="progressbar progressDOTNET"
                          id="progress-dotNet"
                        ></span>
                      </li>
                      <label>Python</label>
                      <span className="percent" id="angular-pourcent"></span>
                      <li>
                        <span
                          className="progressbar progressPYTHON"
                          id="progress-python"
                        ></span>
                      </li>
                      <label>SQL</label>
                      <span className="percent" id="angular-pourcent"></span>
                      <li>
                        <span
                          className="progressbar progressSQL"
                          id="progress-sql"
                        ></span>
                      </li>
                      <label>Visual Basic & VBA</label>
                      <span className="percent" id="angular-pourcent"></span>
                      <li>
                        <span
                          className="progressbar progressVISUALBASIC"
                          id="progress-vb"
                        ></span>
                      </li>
                    </ul>
                  </div>
                  <div className="box item3">
                    <ul className={`skills-bar-container ${classes.text}`}>
                      <h3>Web Development</h3>
                      <label>HTML & CSS</label>
                      <span className="percent"></span>
                      <li>
                        <span
                          className="progressbar progressHTML"
                          id="progress-html"
                        ></span>
                      </li>
                      <label>JavaScript / jQuery</label>
                      <span className="percent"></span>
                      <li>
                        <span
                          className="progressbar progressJAVASCRIPT"
                          id="progress-js"
                        ></span>
                      </li>
                      <label>PHP</label>
                      <span className="percent"></span>
                      <li>
                        <span
                          className="progressbar progressPHP"
                          id="progress-php"
                        ></span>
                      </li>
                      <label>Actionscript</label>
                      <span className="percent"></span>
                      <li>
                        <span
                          className="progressbar progressACTIONSCRIPT"
                          id="progress-as"
                        ></span>
                      </li>
                      <label>Angular-cli & js</label>
                      <span className="percent"></span>
                      <li>
                        <span
                          className="progressbar progressANGULAR"
                          id="progress-angular"
                        ></span>
                      </li>

                      <label>ReactJs</label>
                      <span className="percent"></span>
                      <li>
                        <span
                          className="progressbar progressREACT"
                          id="progress-react"
                        ></span>
                      </li>
                    </ul>
                  </div>
                </Grid>

                <Grid item xs={6}>

                {/* <center><img src={GithubIcon} width="50px" height="50px" alt="" /></center> */}
                <h2>GitHub</h2>

                  <div className="container">
                    <Filter
                      todosPerPage={todosPerPage}
                      setTodosPerPage={setTodosPerPage}
                    />
                    <br />

                    <GitHubData todos={currentTodos} />
                    <Pagination
                      todosPerPage={todosPerPage}
                      totalTodos={todos.length}
                      paginate={paginate}
                    />
                  </div>

                  {/*                    
                   
                  //  <p class="card-text">Last updated: {{repo.updated_at}}</p>
                  // <p class="card-text">{{repo.description}}</p>
                  // <p class="card-text">Size: {{repo.size}} KB</p> */}
                </Grid>
                <Grid item xs={6}>
                  <GithubCard />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </section>
    </>
  );
}

export default Skills;
const styles = {
  cardStyle: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    maxHeight: "400px",
    minWidth: "300px",
    transitionDuration: "0.3s",
    borderRadius: "4px",
    transition: "all 250ms linear",
    boxShadow: 10,
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
    // marginBottom: "10px",
  },
  imgStyle: {
    transition: "all 250ms ease-in-out",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
    },
  },
};
