import React, { useEffect, useState } from "react";

import { Route, Routes } from "react-router-dom";
import "./App.css";
import Account from "./Components/Account/Account";

import AddBlogpost from "./Components/Blog/BlogComponent/AddBlogPost/AddBlogPost";
import BlogList from "./Components/Blog/BlogComponent/BlogList/BlogList";
import BlogPostDetail from "./Components/Blog/BlogComponent/BlogPostDetail/BlogPostDetail";
import EditBlogpost from "./Components/Blog/BlogComponent/EditBlogPost/EditBlogPost";

import AddProjectPost from "./Components/Blog/ProjectComponent/AddProjectPost/AddProjectPost";
import EditProjectPost from "./Components/Blog/ProjectComponent/EditProjectPost/EditProjectPost";
import ProjectList from "./Components/Blog/ProjectComponent/ProjectList/ProjectList";
import ProjectPostDetail from "./Components/Blog/ProjectComponent/ProjectPostDetail/ProjectPostDetail";

import AddPortfolioPost from "./Components/Blog/PortfolioComponent/AddPortfolioPost/AddPortfolioPost";
import EditPortfoliopost from "./Components/Blog/PortfolioComponent/EditPortfolioPost/EditPortfolioPost";
import PortfolioList from "./Components/Blog/PortfolioComponent/PortfolioList/PortfolioList";
import PortfolioPostDetail from "./Components/Blog/PortfolioComponent/PortfolioPostDetail/PortfolioPostDetail.jsx";

import NotFound from "./Components/NotFound/NotFound";
import ScrollToTop from "./Components/ScrollUp/ScrollUp";
import SignIn from "./Components/SignIn/SignIn";
import SocialMediaBar from "./Components/SocialMediaBar/SocialMediaBar";
import AdminProtectedRoute from "./context/AdminProtectedRoute";
import ProtectedRoute from "./context/ProtectedRoute";
import AppLayout from "./layouts/AppLayout";
import "./styles.scss";

import Home from "./Components/Home/Home";

import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import AddGamingPost from "./Components/Blog/GamingComponent/AddGamingPost/AddGamingPost";
import EditGamingpost from "./Components/Blog/GamingComponent/EditGamingPost/EditGamingPost";
import GamingList from "./Components/Blog/GamingComponent/GamingList/GamingList";
import GamingPostDetail from "./Components/Blog/GamingComponent/GamingPostDetail/GamingPostDetail";
import ChangeHistory from "./Components/ChangeHistoryComponent/ChangehistoryView/ChangeHistory";
import ChangeHistoryAdmin from "./Components/ChangeHistoryComponent/ChangeHistoryAdmin/ChangeHistoryAdmin";
import Navbar from "./Components/Navbar/Navbar";
import Particle from "./Components/Particles/Particle";
import ParticleLight from "./Components/Particles/ParticleLight";
import Skills from "./Components/Skills/Skills/Skills";
import PrivacyPolicy from "./Components/Footer/PrivacyPolicy/PrivacyPolicy.jsx";

function App() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // THEME SECTION
  // DARK THEME
  const darkTheme = createTheme({
    palette: {
      type: "dark",
      color: {
        white: "#ffffff",
        FooterBackground: "#1a2332",
        black: "#000000",
        lightBlack: "#00000030",
        lightGreen: "#9fef00",
        lightBlue: "#a4b1cd",
        BackgroundBlogCards: "#a4b1cd",
        lightBlueForFooter: "#ffffff",
        lightBlueForTags: "#1a2332",
        lightBlueForBlogDetails: "#eaeaea",
        NavbarBackground: "#1a2332",
        lightGrey: "#1a2332",
        deepLightGrey: "#141d2b",
        deepLightGreyForBlogCards: "#172a4d",
        deepLightGreyForBlogDetails: "#151515b3",
        deepLightGreyForTimeline: "#1a2332",
        yellow: "#efeb00",
        red: "#ce0ff5",
        redForBlogCards: "#ff8800",
        lightRed: "#dd74f2",
        limeGreen: "#e68ce3",
        limeGreenForReset: "#f77834",
        limeGreenForBlogCards: "#63c0f5",
        limeGreenForBlogCardsReadMore: "#ff8800",
        orange: "#1a2332",
        orangeForPag: "#9fef00",

        //Blog
        SecurityTypeCategoryTagColour: "#8bc90f",

        //Blogcards
        FontColorForCategoryCards: "#ffffff",
        orangeForBlogCards: "#0d6efd",
        orangeForProjectCards: "#f1c232",

        //Navbar
        DropdownMenuBackground: "#141d2b",
        DropdownMenuBackgroundHover: "#637fab",

        //PortfolioDetail
        PortfolioDetailTitleColour: "#dd74f2",
        PortfolioCardBackground: "#443e69",
        PortfolioCardDescription: "#21db81",
        PortfolioCardTitle: "#FFFFFF",
        PortfolioCardDate: "#db2187",
        PortfolioCardFooter: "#2d2e2e",

        //Categories on cards
        HackTheBoxCategoryCard: "#9fef00",
        TryHackMeCategoryCard: "#f922a3",
        TutorialsCategoryCard: "#3ffd5a",
        SecurityCategoryCard: "#d4190c",
        OtherCategoryCard: "#ff8c00",
        KnowledgeCategoryCard: "#e3b92b",
        PortswiggerCategoryCard: "#c24e2d",
        HTBAcademyCategoryCard: "#0aa82c",
        BugBountyCategoryCard: "#b807a6",
        BackgroundCards: "#7bd667",

        //Scroll to top
        ScrollToTop: "#7b50a1",

        //ChangehistoryLink on homepage
        ReadMoreLinkColour: "#a868e3",
        ListItemFontColour: "#FFFFFF",

        //Skills Github
        GithubTitleColor: "#000000",
        GithubDescriptionColor: "#FFFFFF",
        GithubDateColor: "#63c0f5",
        //editor

        EditorForDisplayPreviewTheme: "default",
      },
    },
    typography: {
      fontFamily: "Roboto, sans-serif",
    },
  });

  // LIGHT THEME
  const lightTheme = createTheme({
    palette: {
      type: "light",
      color: {
        white: "#000000",
        FooterBackground: "#f9fafb",
        black: "#ffffff",
        lightBlack: "#00000030",
        lightGreen: "#444444",
        darkGreen: "#444444",
        lightBlue: "#444444",
        BackgroundBlogCards: "#444444",
        lightBlueForFooter: "#444444",
        lightBlueForTags: "#ADD8E6",
        lightBlueForBlogDetails: "#444444",
        NavbarBackground: "#f9fafb",
        lightGrey: "#e9ecef",
        deepLightGrey: "#DDD",
        deepLightGreyForBlogCards: "#DDD",
        deepLightGreyForBlogDetails: "#DDD",
        deepLightGreyForTimeline: "#DDD",
        yellow: "#444444",
        red: "#f44336",
        redForBlogCards: "#f44336",
        lightRed: "#ff7f7f",
        limeGreen: "#9fef00",
        limeGreenForReset: "#9fef00",
        limeGreenForBlogCards: "#8bc90f",
        limeGreenForBlogCardsReadMore: "#8bc90f",
        orange: "#f77834",
        orangeForPag: "#f77834",
        FontColorForCategoryCards: "#ffffff",
        orangeForProjectCards: "#f77834",

        //Home
        HomeHeadingColor: "#000000",

        //Blog
        SecurityTypeCategoryTagColour: "#8bc90f",

        //Blogcards
        FontColorForCategoryCards: "#ffffff",
        orangeForBlogCards: "#0d6efd",
        orangeForProjectCards: "#f1c232",

        //Navbar
        DropdownMenuBackground: "#a1a1a1",
        DropdownMenuBackgroundHover: "#cccbc8",

        //PortfolioPostDetail
        PortfolioDetailTitleColour: "#5eeba4",
        PortfolioCardBackground: "#DDD",
        PortfolioCardTitle: "#7d6aba",
        PortfolioCardDescription: "#262526",
        PortfolioCardDate: "#db2187",
        PortfolioCardFooter: "#dbdbdb",

        //Categories
        HackTheBoxCategoryCard: "#9fef00",
        TryHackMeCategoryCard: "#f922a3",
        TutorialsCategoryCard: "#3ffd5a",
        SecurityCategoryCard: "#d4190c",
        OtherCategoryCard: "#ff8c00",
        KnowledgeCategoryCard: "#338703",
        PortswiggerCategoryCard: "#d18b77",
        HTBAcademyCategoryCard: "#0cd48e",
        BugBountyCategoryCard: "#07a6b8",
        BackgroundCards: "#7bd667",
        searchForAnyPostPageBackground: "#DDD",
        searchForAnyPostPageFontColor: "000000",

        //ScrollToTopComponent
        ScrollToTop: "#50a153",

        //ChangehistoryLink on homepage
        ReadMoreLinkColour: "#1dc4de",

        //ChangeHistory
        ListItemFontColour: "#FFFFFF",

        //Skills Github
        GithubTitleColor: "#000000",
        GithubDescriptionColor: "#000000",
        GithubDateColor: "#63c0f5",

        //md preview
        EditorForDisplayMD: "light",
        EditorForDisplayPreviewTheme: "vuepress",
      },
    },
    typography: {
      fontFamily: "Roboto, sans-serif",
    },
  });

  // THEME STATES
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem("theme") || "dark"
  );

  const toggleThemeMode = () => {
    const newTheme = themeMode === "dark" ? "light" : "dark";
    setThemeMode(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  const theme = themeMode === "light" ? lightTheme : darkTheme;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {themeMode === "light" ? <ParticleLight /> : <Particle />}

      <div
        style={{
          width: "100%",
          height: "100%",
          background:
            themeMode === "light"
              ? "linear-gradient(to left, rgb(202, 207, 205), rgb(147, 207, 203))"
              : "linear-gradient(to left, rgb(87, 97, 105), rgb(17, 24, 38))",
        }}
      >
        <AppLayout>
          <ScrollToTop />
          <SocialMediaBar />

          {/* MY NAVBAR */}
          <Navbar themeMode={themeMode} toggleThemeMode={toggleThemeMode} />
          {/* MY NAVBAR */}

          {/*BLOG */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/bloglist" element={<BlogList />} />
            <Route path="/blog/:id" element={<BlogPostDetail />} />
            {/* <Route path="/hidden1337" element={<Hidden1337/>}/> */}

            <Route
              path="/add-blogpost"
              element={
                <AdminProtectedRoute>
                  <AddBlogpost />
                </AdminProtectedRoute>
              }
            />

            <Route
              path="/bupdate/:id"
              element={
                <AdminProtectedRoute>
                  <EditBlogpost />
                </AdminProtectedRoute>
              }
            />

            {/*Project */}

            <Route path="/projectlist" element={<ProjectList />} />
            <Route path="/projects/:id" element={<ProjectPostDetail />} />

            <Route
              path="/add-projectpost"
              element={
                <AdminProtectedRoute>
                  <AddProjectPost />
                </AdminProtectedRoute>
              }
            />

            <Route
              path="/pupdate/:id"
              element={
                <AdminProtectedRoute>
                  <EditProjectPost />
                </AdminProtectedRoute>
              }
            />
            {/*Portfolio */}

            <Route path="/portfoliolist" element={<PortfolioList />} />
            <Route path="/portfolio/:id" element={<PortfolioPostDetail />} />

            <Route
              path="/add-portfoliopost"
              element={
                <AdminProtectedRoute>
                  <AddPortfolioPost />
                </AdminProtectedRoute>
              }
            />

            <Route
              path="/poupdate/:id"
              element={
                <AdminProtectedRoute>
                  <EditPortfoliopost />
                </AdminProtectedRoute>
              }
            />

            {/*Gaming */}

            {/* <Route path="/gaminglist" element={<GamingList />} />
            <Route path="/gaming/:id" element={<GamingPostDetail />} /> */}

            <Route
              path="/add-gamingpost"
              element={
                <AdminProtectedRoute>
                  <AddGamingPost />
                </AdminProtectedRoute>
              }
            />

            <Route
              path="/gupdate/:id"
              element={
                <AdminProtectedRoute>
                  <EditGamingpost />
                </AdminProtectedRoute>
              }
            />

            <Route path="/sign-in" element={<SignIn />} />
            <Route
              path="/account"
              element={
                <ProtectedRoute>
                  <Account />
                </ProtectedRoute>
              }
            />

            <Route path="/skills" element={<Skills />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />




            <Route path="/changehistory" element={<ChangeHistory />} />
            <Route
              path="/add-changehistory"
              element={
                <AdminProtectedRoute>
                  <ChangeHistoryAdmin />
                </AdminProtectedRoute>
              }
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </AppLayout>
      </div>
    </ThemeProvider>
  );
}

export default App;
