import { Box, Grid } from "@mui/material";
import React from "react";
import {
  SiCodesandbox,
  SiVisualstudiocode,
  SiCodepen,
  SiPostman,
  SiGithub,
  SiVim,
  SiIntellijidea,
  SiStack,
  SiTorbrowser,
  SiVirtualbox,
  SiDiscord,
  SiReact,
  SiAngular,
  SiKalilinux,
  SiTypescript,
  SiAmazonaws,
  SiKubernetes,
  SiDocker
} from "react-icons/si";

import { ReactComponent as Burp } from "../../Assets/burp.svg";

function Toolstack() {
  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xl={2} lg={2} md={4} sm={4} xs={4}>
        <Box className="tech-icons">
          <SiKalilinux fill="white" />
          <p>Kali Linux</p>
        </Box>
      </Grid>
      <Grid item xl={2} lg={2} md={4} sm={4} xs={4}>
        <Box className="tech-icons">
          <Burp height={65} width={65} fill="red" />
          <p>Burp Suite</p>
        </Box>
      </Grid>
      <Grid item xl={2} lg={2} md={4} sm={4} xs={4}>
        <Box className="tech-icons">
          <SiVim fill="yellow" />
          <p>vim</p>
        </Box>
      </Grid>
      <Grid item xl={2} lg={2} md={4} sm={4} xs={4}>
        <Box className="tech-icons">
          <SiVisualstudiocode fill="blue" />
          <p>VS Code</p>
        </Box>
      </Grid>

      <Grid item xl={2} lg={2} md={4} sm={4} xs={4}>
        <Box className="tech-icons">
          <SiGithub fill="purple" />
          <p>Github</p>
        </Box>
      </Grid>

      <Grid item xl={2} lg={2} md={4} sm={4} xs={4}>
        <Box className="tech-icons">
          <SiTorbrowser fill="lime" />
          <p>Tor Browser</p>
        </Box>
      </Grid>

      <Grid item xl={2} lg={2} md={4} sm={4} xs={4}>
        <Box className="tech-icons">
          <SiAmazonaws fill="orange" />
          <p>AWS</p>
        </Box>
      </Grid>
      <Grid item xl={2} lg={2} md={4} sm={4} xs={4}>
        <Box className="tech-icons">
          <SiKubernetes fill="cyan" />
          <p>Kubernetes</p>
        </Box>
      </Grid>
      <Grid item xl={2} lg={2} md={4} sm={4} xs={4}>
        <Box className="tech-icons">
          <SiVirtualbox fill="white" />
          <p>Virtualbox</p>
        </Box>
      </Grid>

      <Grid item xl={2} lg={2} md={4} sm={4} xs={4}>
        <Box className="tech-icons">
          <SiReact fill="cyan" />
          <p>React</p>
        </Box>
      </Grid>
      <Grid item xl={2} lg={2} md={4} sm={4} xs={4}>
        <Box className="tech-icons">
          <SiAngular fill="red" />
          <p>Angular</p>
        </Box>
      </Grid>
      <Grid item xl={2} lg={2} md={4} sm={4} xs={4}>
        <Box className="tech-icons">
          <SiDocker fill="magenta" />
          <p>Docker</p>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Toolstack;
