import { Box, Container } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import Particle from "../Particles/Particle";
import "./SignIn.scss";
const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { signIn } = UserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const res = await signIn(email, password);
      if (res) {
        navigate("/account");
      }
    } catch (e) {
      setError(e.message);
      console.log(e.message);
    }
  };

  return (
    <Container maxWidth="sm" style={{ height: "100%" }}>
      {/* <Particle /> */}
      <Box className="login-section">
        <form
          onSubmit={handleSubmit}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "120px",
          }}
        >
          <Box sx={{ marginBottom: "10px", width: "100%" }}>
            <label>Email Address</label>
            <input
              className="form-control input-text-box"
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="email address..."
            />
          </Box>
          <Box sx={{ marginBottom: "10px", width: "100%" }}>
            <label>Password</label>
            <input
              className="form-control input-text-box"
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
          </Box>

          <Box
            sx={{
              marginBottom: "10px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button className="btn-sign-in" type="submit">
              Sign in
            </button>
          </Box>
        </form>
        {error && <p style={{ textAlign: "center", color: "red" }}>{error}</p>}
      </Box>
    </Container>
  );
};

export default Signin;
