import React, { useEffect, useRef, useState } from "react";
import { db, storage } from "../../../../firebase";

import { Box, Container, Typography } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import { toast } from "react-toastify";
import { UserAuth } from "../../../../context/AuthContext";

import { MdEditor } from "md-editor-rt";
import "md-editor-rt/lib/style.css";
import "./EditProjectPost.scss";

const initialState = {
  title: "",
  author: "",
  tags: [],
  trending: "no",
  category: "",
  description: "",
  content: [],
  timestamp: "",
};

const categories = ["Hardware", "Software", "Graphics", "Videos", "3D"];



const EditProjectpost =  ({ inline, children = [], className, ...props }) => {
  const editorRef = useRef(null);
  const { user } = UserAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  if (location.pathname.includes("update")) {
    //  console.log("Location is Update", location.pathname);
  } else {
    //  console.log("Location is Add", location.pathname);
  }

  const [form, setForm] = useState(initialState);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(null);
  const [editorData, setEditorData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState([]);
  const [EditorKey, setEditorKey] = useState("");

  useEffect(() => {
    const q = query(collection(db, "settings"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let todosArr = [];
      querySnapshot.forEach((doc) => {
        todosArr.push({ ...doc.data(), id: doc.id });
      });
      setSettings(todosArr);

      todosArr.forEach((x) => {
        setEditorKey(x.key);
      });
    });
    return () => unsubscribe();
  }, []);

  // settings.map((todo, index) => (

  //   setEditorKey(todo.push)

  // ))

  // console.log(EditorKey);

  const {
    title,
    author,
    tags,
    category,
    trending,
    description,
    content,
    timestamp,
  } = form;
  useEffect(() => {
    const uploadFile = () => {
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setProgress(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
            toast.info("Image uploaded successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setForm((prev) => ({ ...prev, imgUrl: downloadUrl }));
          });
        }
      );
    };

    file && uploadFile();
  }, [file]);

  useEffect(() => {
    try {
      // setLoading(true);
      id && getBlogDetail();
      // setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);

    }
  }, [id]);

  const getBlogDetail = async () => {
    setLoading(true);
    const docRef = doc(db, "projects", id);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      console.log("Data retrieved from DB", snapshot.data());

      setForm({ ...snapshot.data() });
    }
    setLoading(false);

  };

  const handleDescriptionChange = (e) => {
    setForm({ ...form, description: e.target.value });
  };

  const handleTags = (tags) => {
    setForm({ ...form, tags: tags });
  };
  const handleAuthorChange = (e) => {
    setForm({ ...form, author: e.target.value });
  };

  const handleTitleChange = (e) => {
    setForm({ ...form, title: e.target.value });
  };

  const handleTrending = (e) => {
    setForm({ ...form, trending: e.target.value });
  };

  const [selectedCat, setSelectedCat] = useState("");

  const onCategoryChange = (e) => {
    setForm({ ...form, category: e.target.value });
    setSelectedCat(e.target.value);
  };

  const onEditorChange = (newContent) => {
    setForm({ ...form, content: newContent });
    setEditorData(newContent);
  };

  const handleOs = (e) => {
    setForm({ ...form, os: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (category && tags && title && description && trending) {
      console.log("Content is", content);
      if (!id) {
        try {
          await addDoc(collection(db, "projects"), {
            ...form,
            timestamp: serverTimestamp(),
            // userId: user.uid,
            // content: editorData,
          });
          toast.success("Blog has been created", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        } catch (err) {
          console.log(err);
        }
      } else {
        try {
          await updateDoc(doc(db, "projects", id), {
            ...form,
            timestamp: serverTimestamp(),
            // userId: user.uid,
            // content: editorData,
          });
          toast.success("Blog has been updated", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        } catch (err) {
          console.log(err);
        }
      }
    } else {
      return toast.error("All fields need to be used", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }

    navigate("/projectlist");
  };

  //date
  var str = "";
  if (timestamp > 0) {
    str = new Date(timestamp.seconds * 1000);
  }
  const date_formated_timestamp = moment(str).format(
    "dddd, MMMM Do YYYY, h:mm:ss a"
  );

  // console.log("Content is of type", typeof content, "and is", content);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Container maxWidth="lg" style={{ width: "100%" }}>
      {/* <Particle /> */}
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          position: "relative",
          zIndex: 1,
          textAlign: "center",
          alignItems: "center",
          paddingTop: "120px",
        }}
      >
        <Typography
          style={{ fontSize: "22px", color: "#ff9100", fontWeight: "bold" }}
        >
          Update blog
        </Typography>

        <input
          style={{ width: "50%", marginBottom: 10 }}
          disabled
          type="text"
          className="form-control input-text-box"
          name="timestamp"
          value={date_formated_timestamp}
        />

        <form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
          onSubmit={handleSubmit}
        >
          <input
            style={{ width: "50%", marginBottom: 10 }}
            type="text"
            className="form-control input-text-box"
            placeholder="author"
            name="author"
            value={author}
            onChange={handleAuthorChange}
          />

          <input
            style={{ width: "50%", marginBottom: 10 }}
            type="text"
            className="form-control input-text-box"
            placeholder="title"
            name="title"
            value={title}
            onChange={handleTitleChange}
          />
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              height: 500,
              width: "100%",
              columnGap: 10,
              marginTop: "20px",
            }}
          >
            <Box style={{ flex: 1 }}>
  
            <MdEditor
                theme="light"
                codeTheme="github"
                editorId="md-prev"
                previewTheme={props.previewTheme}
                htmlPreview
                modelValue={content.toString()}
                onChange={(data) => {
                  onEditorChange(data);
                }}
                language='en-US'
                toolbarsExclude={["github"]}
                noPrettier
                tabWidth={4}
                showCodeRowNumber
                //  katex={katex}
                tableShape={[10, 10]}
                // noMermaid
                // placeholder="placeholder"
                // noKatex
                // noHighlight
                // sanitize={(h) => `<a href="#">aaa</a>${h}`}
                // scrollAuto={false}
                // noIconfont
                // codeStyleReverse={false}
                // codeStyleReverseList={['mk-cute']}
                autoFocus
                // disabled
                // readOnly
                // maxLength={10}
                // autoDetectCode
                // onHtmlChanged={console.log}
                toolbars={[
                  "bold",
                  "underline",
                  "italic",
                  "strikeThrough",
                  "-",
                  "title",
                  "sub",
                  "sup",
                  "quote",
                  "unorderedList",
                  "orderedList",
                  "task",
                  "-",
                  "codeRow",
                  "code",
                  "link",
                  "image",
                  "table",
                  "mermaid",
                  "katex",
                  "-",
                  "revoke",
                  "next",
                  0,
                  1,
                  2,
                  "=",
                  "prettier",
                  "pageFullscreen",
                  "fullscreen",
                  "preview",
                  "htmlPreview",
                  "catalog",
                  "github",
                ]}
              />
            </Box>
          </Box>

          <div className="col-12 py-3">
            <TagsInput
              value={tags}
              onChange={handleTags}
              name="tags"
              placeHolder="Enter tags"
            />
          </div>
          <div className="col-12 py-3">
            <p className="trending">is the post trending?</p>
            <div className="form-check-inline mx-2">
              <input
                type="radio"
                className="form-check-input"
                value="yes"
                name="radioOption"
                checked={trending === "yes"}
                onChange={handleTrending}
              />
              <label htmlFor="radioOption" className="form-check-label">
                Yes&nbsp;
              </label>
              <input
                type="radio"
                className="form-check-input"
                value="no"
                name="radioOption"
                checked={trending === "no"}
                onChange={handleTrending}
              />
              <label htmlFor="radioOption" className="form-check-label">
                No
              </label>
            </div>
          </div>
          <div className="col-12 py-3">
            <select
              value={category}
              onChange={onCategoryChange}
              className="catg-dropdown"
            >
              <option>Category</option>
              {categories.map((option, index) => (
                <option value={option || ""} key={index}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          {selectedCat === "HackTheBox" && <></>}

          <div className="col-12 py-3">
            <textarea
              className="form-control description-box"
              placeholder="description"
              value={description}
              name="description"
              onChange={handleDescriptionChange}
            />
          </div>
          {id && (
            <img
              src={form.imgUrl}
              alt={form.title}
              style={{
                objectFit: "contain",
                maxWidth: "300px",
                marginBottom: "20px",
              }}
            />
          )}
          <div className="mb-3">
            <input
              type="file"
              className="form-control"
              onChange={(e) => setFile(e.target.files[0])}
            />
          </div>
          <div style={{ marginBottom: 150 }}>
            <button
              style={{
                backgroundColor: "#1a2332",
                color: "#fff",
                padding: "10px 20px",
                border: "none",
                borderRadius: "20px",
              }}
              type="submit"
              disabled={progress !== null && progress < 100}
            >
              Update
            </button>
          </div>
        </form>
      </Box>
    </Container>
  );
};

export default EditProjectpost;
